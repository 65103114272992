
.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    padding: 0 30px;
    font-family: "CerebriSans", sans-serif;
    box-sizing: border-box;
    z-index: 100;
    will-change: height, box-shadow;
    transition: height 0.5s linear, box-shadow 0.1s ease, background-color 0.3s ease;
    /* overflow: hidden; */
    margin: 0;
   
    /* Glassmorphism effect */
    /* background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px); 
    -webkit-backdrop-filter: blur(10px); 
   
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);  */
}


.header.scrolled {
    background-color: white;
   
  }



  .nav__dropdown_title {
    color: white; /* or your default color */
    transition: color 0.3s ease; /* smooth transition */
   
  }
  
  .nav__dropdown_title.scrolled {
    color: black !important; /* color when scrolled */
  }
  
.header.scrolled .header__logo img {
    width: 110px; /* Reduced size when scrolled */
  
  }
.header__wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items:center;
    width: 100%;
    height: 100%;
  
   
   
    
}

.header__nav {
    display: none;
   
}

.header__logo {
    display: flex;
    align-items: center;
 
   
}

.header__logo img {
    width: 30%;
    height: 20%;
   
}

.header__button {
    display: none
}




  .nav__dropdown a.navLink:hover {
    color: blue !important;
    
  }
  

  @media (max-width: 768px) {
    .header {
        height:10%;
       /* background-color: black; */
    }
    .header__logo img {
        width: 110px;
       
    }
}

@media (min-width: 768px) {
    .header {
        height:80px;
       
    }

    .header__wrapper {
        display: grid;
        grid-template-areas: '. . .';
        grid-auto-columns: 1fr auto auto;
        grid-gap: 0 30px
    }

    .header__logo img {
        width: 110px;
        height: auto;
        padding-top: 10px;
       
    }

    .header__button {
        min-width: unset;
        width: fit-content;
        display: block;
        padding-left: 37px;
        padding-right: 37px
    }
}

@media (min-width: 1366px) {
    .header {
        padding:0;
        height: 100px;
        overflow: visible
    }

    .header__wrapper {
        width: calc(100% - 80px);
        grid-auto-columns: auto 1fr auto;
        margin: 0 auto;
        grid-gap: 0 60px
    }

    .header__hamburger {
        /* display: none !important */
    }

    .header__button {
        font-size: 16px;
        padding: 11px 37px
    }
}

@media (min-width: 1920px) {
    .header {
        height:100px
    }

    .header__wrapper {
        width: 1720px;
        grid-gap: 0 70px
    }

    .header__logo img {
        width: 220px
    }

    .header__button {
        font-size: 18px;
        padding: 16px 46px
    }
}

.nav {
    display: none !important
}

@media (min-width: 820px) {
    .nav {
        height:100%;
        display: flex !important;
        justify-content:end
    }

    .nav__wrapper {
        display: flex;
        align-items: center;
        grid-gap: 0;
        padding-top: 10px;
    }

    .nav__dropdown {
        display: flex;
        align-items: center;
        height: 100%;
        background: transparent;
        padding: 0 20px;
        z-index: 3
    }

    .nav__dropdown:last-child {
        padding-right: 0
    }
    /* .header__logo img {
        width: 150px;
        height: auto;
        padding-top: 10px;
    } */

    .nav__dropdown_title {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        /* color: white; */
        font-size: 16px;
        line-height: normal;
        font-weight: 500;
        transition: all 0.1s ease;
        z-index: 2;
        cursor: pointer;
       
    }

    .nav__dropdown_title:after {
        content: '';
        width: 100%;
        height: 1px;
        background: transparent;
        border-radius: 10px;
        position: absolute;
        left: 0;
        bottom: 0;
       
        
    }

    .nav__dropdown:hover .navContent-wrap {
        display: block
    }

    .nav__dropdown:hover .nav__dropdown_title {
        color: #04f;
       
        
    }

    .nav__dropdown:hover .nav__dropdown_title:after {
        background: transparent;
       
       
    }

    .navLink {
        color: #212322
    }

    .navLink.active {
        color: #006b95
    }

    .navContent {
        position: relative;
        background: #FFFFFF;
        padding: 18px 30px 30px 30px;
        box-shadow: 0 10px 20px 0 rgba(33,35,34,0.1);
        border-radius: 10px;
        z-index: 3;
        
    }

    .navContent-wrap {
        display: none;
        position: absolute;
        height: auto;
        top: 100%
    }

    .navContentSub {
        display: grid;
        grid-template-areas: 'sub1 item' 'sub2 item';
        grid-auto-rows: min-content 1fr;
        grid-auto-columns: 170px 1fr;
        align-items: flex-start
    }

    .navContent-subtitle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        grid-gap: 0 20px;
        font-family: "CerebriSans",sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        cursor: pointer
    }

    .navContent-subtitle:after {
        content: '';
        display: block;
        width: 16px;
        height: 13px;
        /* background-image: url("/_images/icons/menu-icon.svg"); */
        background-repeat: no-repeat;
        filter: grayscale(1);
        transform: rotate(-90deg);
        opacity: 0.3
    }

    .navContent-subtitle:hover,.navContent-subtitle.active {
        color: #04f
    }

    .navContent-subtitle:hover:after,.navContent-subtitle.active:after {
        filter: grayscale(0);
        opacity: 1
    }

    .navContent-subtitle:hover:before,.navContent-subtitle.active:before {
        display: block
    }

    .navContent-subtitle.sub1 {
        grid-area: sub1;
        padding-bottom: 30px;
        padding-top: 12px
    }

    .navContent-subtitle.sub1:hover {
        border-color: #04f;
        
    }

    .navContent-subtitle.sub1:hover ~ .boxH1 {
        display: grid
    }

    .navContent-subtitle.sub2 {
        grid-area: sub2
    }

    .navContent-subtitle.sub2:hover {
        border-color: #04f
    }

    .navContent-subtitle.sub2:hover ~ .boxH2 {
        display: grid
    }

    .navContent-box {
        grid-area: item;
        display: none;
        grid-template-areas: '. .';
        grid-auto-flow: column;
        grid-gap: 0 40px;
        padding-left: 30px
    }

    .navContent-box:hover {
        display: grid
    }

    .navContent-box.boxH1 {
        grid-template-rows: repeat(3, auto)
    }

    .navContent-box.boxH2 {
        grid-template-rows: repeat(4, auto)
    }

    .navContent-item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: fit-content;
        font-family: "CerebriSans",sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        padding: 12px 0;
        cursor: pointer;
        text-decoration: none;
    }

    .navContent-item.mb20 {
        margin-bottom: 20px
    }

    .navContent-item span:last-child {
        display: block;
        font-family: "Montserrat",sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        white-space: break-spaces
    }

    .navContent-item:after {
        content: '';
        position: absolute;
        background: #f5f5f5;
        width: calc(100% + 20px);
        height: calc(100% + 10px);
        left: -10px;
        top: -5px;
        z-index: -1;
        opacity: 0;
        border-radius: 10px;
        pointer-events: none
    }

    .navContent-item:hover {
        color: #006b95
    }

    .navContent-item:hover:after {
        opacity: 1
    }

    .navContent-item:hover span {
        color: #04f
    }

    .navContent.mod-column {
        display: flex;
        flex-direction: column
    }

    .navContent.mod-column-txt {
        justify-content: space-between
    }

    .navContent.mod-row4 {
        display: grid;
        grid-auto-flow: column;
        grid-template-rows: repeat(4, auto);
        grid-gap: 0 40px
    }

    .navContent.mod-row5 {
        display: grid;
        grid-auto-flow: column;
        grid-template-rows: repeat(5, auto);
        grid-gap: 0 40px
    }

    .navContent.mod-row6 {
        display: grid;
        grid-auto-flow: column;
        grid-template-rows: repeat(6, auto);
        grid-gap: 0 40px
    }

    .navContent.mod-row7 {
        display: grid;
        grid-auto-flow: column;
        grid-template-rows: repeat(7, auto);
        grid-gap: 0 40px
    }
}

@media (min-width: 1920px) {
    .nav__dropdown {
        padding:0 30px
    }

    .nav__dropdown_title {
        font-size: 22px
    }

    .navContent {
        padding: 28px 40px 40px 40px
    }

    .navContentSub {
        grid-auto-columns: 221px 1fr
    }

    .navContent-subtitle {
        font-size: 22px;
        line-height: 32px
    }

    .navContent-subtitle:after {
        width: 20px;
        height: 14px
    }

    .navContent-subtitle.sub1 {
        padding-bottom: 40px
    }

    .navContent-box {
        grid-gap: 0 40px;
        padding-left: 60px;
        width: 811px
    }

    .navContent-item {
        font-size: 18px;
        line-height: 23px
    }

    .navContent-item span:last-child {
        font-size: 16px;
        line-height: 24px
    }

    .navContent-item:after {
        width: calc(100% + 40px);
        height: calc(100% + 10px);
        left: -20px
    }
}

.navLink[href="/case-study"] {
    color: white;
}
.navLink[href="/contact-us"] {
    color: white;
}
.navLink[href="/career"] {
    color: white;
}
/* .navLink[href="/about-us"] {
    color: white;
} */
.header.scrolled .nav__dropdown_title {
    color: black; /* Change to black when scrolled */
  }
  .header.scrolled .nav__dropdown_title,
  .header.scrolled .navLink[href="/training"] {
    color: black; /* Change to black when scrolled */
  }
