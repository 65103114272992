/* section1.css */

/* Section Styling */
.section1 {
    position: relative; /* Allows positioning of overlay */
    overflow: hidden; /* Hides overflowing content */
    width: 100%; /* Full width of parent */
    height: auto; /* Height adjusts to content */
    padding: 40px 0; /* Padding around the section */
    background-color: #f0f0f5; /* Light gray background */
  }
  
  .section-container1 {
    position: relative; /* Allows positioning of overlay */
    max-width: 1200px; /* Maximum width of the content container */
    margin: 0 auto; /* Center-align container */
    display: flex; /* Flexbox layout */
    align-items: center; /* Center items vertically */
    justify-content: space-between; /* Space between elements */
    padding: 0 20px; /* Horizontal padding */
    gap: 20px; /* Space between elements */
  }
  
  .section-container1 picture {
    flex: 1; /* Flex-grow for responsive behavior */
    display: flex; /* Flexbox for picture element */
    align-items: center; /* Center image vertically */
    justify-content: center; /* Center image horizontally */
  }
  
  .section-image1 {
    width: 100%; /* Full width image */
    height: auto; /* Maintain aspect ratio */
    border-radius: 12px; /* Rounded corners */
    filter: brightness(0.9) contrast(1.2); /* Slightly darker and more contrast */
    transition: transform 0.3s ease, filter 0.3s ease; /* Smooth transition */
  }
  
  .section-image1:hover {
    transform: scale(1.03); /* Slight zoom on hover */
    filter: brightness(1) contrast(1); /* Reset filter on hover */
  }
  
  .section-content-overlay {
    flex: 1; /* Flex-grow for responsive behavior */
    background: rgba(255, 255, 255, 0.2); /* Transparent white */
    backdrop-filter: blur(10px); /* Glassmorphism effect */
    -webkit-backdrop-filter: blur(10px); /* Safari support */
    padding: 30px; /* Padding inside the overlay */
    border-radius: 12px; /* Rounded corners */
    border: 1px solid rgba(255, 255, 255, 0.3); /* Light border */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
    color: #fff; /* White text color */
    text-align: center; /* Center text */
  }
  
  .section-content-overlay:hover {
    transform: translateY(-5px); /* Lift effect on hover */
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2); /* Increased shadow on hover */
  }
  
  .section-content-overlay h4 {
    font-size: 2rem; /* Title font size */
    font-weight: bold; /* Bold font weight */
    margin-bottom: 10px; /* Bottom margin */
    color: #fff; /* White text */
  }
  
  .section-content-overlay h6 {
    font-size: 1.2rem; /* Subtitle font size */
    font-weight: 300; /* Light font weight */
    margin: 0; /* No margin */
    color: #f0f0f5; /* Lighter color */
    line-height: 1.5; /* Line height for better readability */
  }
  
  @media (max-width: 768px) {
    .section-container1 {
      flex-direction: column; /* Stack items vertically on smaller screens */
      padding: 0 10px; /* Adjust padding */
    }
  
    .section-image1,
    .section-content-overlay {
      margin-bottom: 20px; /* Space between stacked items */
    }
  }
  