.contact-form-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: black;

}
.innerf{
  width: 90%;
  margin: auto;
  display: flex;
}
.contact-form-left {
  width: 40%;
  padding: 20px;

}
.contact-form-left h2{
  font-size: 16px;
  color:#0056b3;
  margin-bottom: 20px;

}
.contact-form-left h3{
  font-size: 40px;
  color: white;
 font-weight: 600;
 line-height: 1.2;
}
.contact-form-left p{
 
  color: white;
 /* font-weight: 600; */
}
.assured{
  display: flex;
  margin-top: 20px;
}

.tickimg2{
  margin-left: 30px;
}
.contact-form-right {
  width: 60%;
}
.contact-form-right h2 {
 color: white;
 font-size: 16px;
 text-align: left;
 padding-bottom: 15px;
 margin-top: 20px;
}

.emaill{
  margin-top: 50px;
  display: flex;
}
.email2{
  margin-left: 20px;
}
.tickimg1{
  margin-right:10px ;
}
.tickimg2{
  margin-right:10px ;
}
.form-row {
  display: flex;
  justify-content: space-between;
}

.form-group {
  margin-bottom: 20px;
  flex: 1;
  margin-right: 10px;
}

.form-group:last-child {
  margin-right: 0;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 16px;
}
.form-group textarea {
  padding: 12px;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 16px;
  height: 120px !important; /* Adjust the height as needed */
  width: 101.5%; /* Adjust the width as needed */
}


.error-text {
  color: red;
  font-size: 0.875em;
}

.submit-button {
  margin-top: 20px;
  padding: 18px 20px;
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  border-radius: 10px;
  border: 1px solid white;
  font-size: 16px;
  font-weight: 700;
}

.submit-button:hover {
  background-color: #003e95;
  border: none;
}








/* Media queries for responsiveness */
@media (max-width: 768px) {
  .contact-form-container {
    display:block;
    justify-content: space-between;
    padding: 20px;
  }
  .innerf{
    width: 90%;
    margin: auto;
    display: block;
  }
  .contact-form-left,
  .contact-form-right {
    width: 100%;
    padding: 10px;
   
  }
  .contact-form-left h3{
    font-size: 25px;
    color: white;
   font-weight: 600;
  }

  .contact-form-right h2 {
    color: white;
    font-size: 16px;
    text-align: left;
    padding-bottom: 15px;
    margin-top: 20px;
   }
  .form-row {
    flex-direction: column;
  }

  .form-group {
    margin-right: 0;
    min-width: 100%;
  }

}

@media (max-width: 480px) {
  .contact-form-container {
    padding: 10px;
  }

  .contact-form-left,
  .contact-form-right {
    padding: 5px;
  }

  .submit-button {
    width: 100%;
  }
}

/* Textarea specifically */
.form-group textarea {
  resize: none; 
}
