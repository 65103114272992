.hero {
    position: relative;
    margin: 0 auto;
    background: #f5f5f5;
   
    
}

.hero-container {
    padding: 80px 30px 0 30px
}

.hero__info {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
   
    
}

.hero__info_title {
    margin-bottom: 40px;
    white-space: break-spaces
}

.hero__info_subtitle {
    margin-bottom: 40px
}

.hero__image {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    max-width: 315px;
    pointer-events: none;
    margin-left: auto
}

@media (min-width: 768px) {
    .hero {
        /* margin-left:20px;
        margin-right: 20px */
    }

    .hero-container {
        /* padding: 90px 0 0 40px */
    }

    .hero__info_subtitle {
        white-space: break-spaces
    }

    .hero__image {
        margin-top: -184px
    }
}

@media (min-width: 1366px) {
    .hero {
        margin:0;
        width: 100%;
        background: #f5f5f5
    }

    .hero-container {
        position: relative;
        display: grid;
        grid-template-areas: '. .';
        grid-template-columns: 600px auto;
        width: 1080px;
        margin: 0 auto;
        padding: 0
    }

    .hero-container.xl {
        grid-template-columns: 750px auto
    }

    .hero-container:after {
        content: '';
        position: absolute;
        /* background-image: url("../_images/shapes/shape09.svg"); */
        background-repeat: no-repeat;
        background-size: 100% auto;
        width: 200px;
        height: 85px;
        bottom: 0;
        left: -100px;
        background-position: bottom left
    }

    .hero__info {
        max-width: unset;
        padding: 130px 0 240px 0
    }

    .hero__image {
        /* position: absolute; */
        right: -103px;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: unset;
        margin: 0;
        height: 100%
    }

    .hero__image img {
        width: auto;
        height: 100%
    }
}

@media (min-width: 1920px) {
    .hero-container {
        grid-template-columns:700px auto;
        width: 1360px
    }

    .hero-container:after {
        background-size: 100% auto;
        width: 200px;
        height: 160px;
        left: -185px
    }

    .hero__info {
        padding: 180px 0 280px 0
    }

    .hero__info_title {
        margin-bottom: 40px
    }

    .hero__image {
        right: -100px
    }
}

.saasverse-partner {
    position: relative;
    width: 90%;
    height: 16vh;
    margin: 0 auto;
    background:#006b95;
    padding: 20px;
    margin-top: -50px;
    z-index: 2;
    border-radius: 40px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .word {
    padding: 10px;
    color: #006b95;
    font-weight: bold;
    font-size: 1.5rem;
    border-radius: 5px;
  }