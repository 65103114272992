.go-to-top-button {
    position: fixed;
    bottom: 100px;
    right: 25px;
    padding: 10px 15px;
    font-size: 20px;
    background-color: #003e95;
    color: white;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    transition: opacity 0.3s, visibility 0.3s;
  }
  
  .go-to-top-button:hover {
    background-color: black;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .go-to-top-button {
    display: none;
    }
  }
  

  