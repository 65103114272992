/* WhyChooseUs.css */

/* Container Styles */
.containert1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 20px; /* Space between columns */
  padding: 40px 20px; /* Container padding */
  background-color: #f8f9fa; /* Light background for contrast */
  border-radius: 15px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

/* Column Styles */
.columnt1 {
  flex: 1 1 300px; /* Responsive flex basis */
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.columnt1:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

/* Headings */
.columnt1 h2 {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #333;
  text-align: center;
}

.columnt1 h2 span {
  color: #006b95; /* Highlight color */
}

.columnt1 h4 {
  font-size: 1.2rem;
  margin-bottom: 15px;
  color: #666;
  text-align: center;
}

/* Paragraphs */
.columnt1 p {
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
}

/* Bold Text */
.columnt1 p b {
  color: #333;
}

/* Responsive Design */

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .containert1 {
    padding: 20px 10px; /* Adjusted padding for smaller screens */
    gap: 10px; /* Smaller gap for small screens */
  }

  .columnt1 {
    flex: 1 1 100%; /* Full width on smaller screens */
    padding: 15px; /* Adjust padding for smaller screens */
  }

  .columnt1 h2 {
    font-size: 1.5rem; /* Adjusted font size */
  }

  .columnt1 h4 {
    font-size: 1rem; /* Adjusted font size */
  }

  .columnt1 p {
    font-size: 0.9rem; /* Adjusted font size */
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .containert1 {
    padding: 30px 15px; /* Adjusted padding for small devices */
    gap: 15px; /* Adjusted gap for small devices */
  }

  .columnt1 {
    flex: 1 1 100%; /* Full width on small devices */
    padding: 18px; /* Adjust padding for small devices */
  }

  .columnt1 h2 {
    font-size: 1.6rem; /* Adjusted font size */
  }

  .columnt1 h4 {
    font-size: 1.1rem; /* Adjusted font size */
  }

  .columnt1 p {
    font-size: 1rem; /* Adjusted font size */
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .containert1 {
    padding: 40px 20px; /* Adjusted padding for tablets */
    gap: 20px; /* Adjusted gap for tablets */
  }

  .columnt1 {
    flex: 1 1 45%; /* Two columns per row */
    padding: 20px; /* Adjust padding for tablets */
  }

  .columnt1 h2 {
    font-size: 1.8rem; /* Adjusted font size */
  }

  .columnt1 h4 {
    font-size: 1.1rem; /* Adjusted font size */
  }

  .columnt1 p {
    font-size: 1rem; /* Adjusted font size */
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .containert1 {
    padding: 50px 30px; /* Maintain padding for desktops */
    gap: 20px; /* Adjusted gap for desktops */
  }

  .columnt1 {
    flex: 1 1 30%; /* Three columns per row */
    padding: 25px; /* Maintain padding for desktops */
  }

  .columnt1 h2 {
    font-size: 2rem; /* Maintain font size */
  }

  .columnt1 h4 {
    font-size: 1.2rem; /* Maintain font size */
  }

  .columnt1 p {
    font-size: 1rem; /* Maintain font size */
  }
}
