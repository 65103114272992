.gallery-container00 {
    width: 95%;
    height: 150vh;
    margin: 0 auto;
    text-align: center;
  }
  
  .gallery-container00 h2 {
    margin-bottom: 20px;
  }
  
  .gallery-grid00 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .gallery-item00 {
    box-sizing: border-box;
    opacity: 0;
    transform: translateY(100px);
    animation: fadeInUp 0.8s ease forwards;
    position: relative;
  }
  
  .gallery-item00.full-height00 {
    width: 100%;
    height: 100%;
  }
  
  .gallery-item00 img {
    width: 100%;
    height: auto;
    object-fit:cover;
    transition: transform 0.3s ease;
  }
 
  
  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Responsive styles */
  @media (max-width: 1200px) {
    .gallery-item00 img {
      height: 100vh;
    }
  }
  
  @media (max-width: 900px) {
    .gallery-item00 img {
      height: 100vh;
    }
  }
  
  @media (max-width: 600px) {
    .gallery-item00 img {
      height: 100vh;
    }
  }
  