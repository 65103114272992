/* ClientSuccessStories.css */

.success-stories {
    padding: 50px 20px;
    background-color: #f9f9f9;
    text-align: center;
}

.success-stories h2 {
    font-size: 2.5rem;
    margin-bottom: 30px;
}

.slick-slider {
    margin: 0 auto;
    max-width: 1200px;
}

.story-card {
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px;
    text-align: left;
    outline: none; /* Remove focus outline */
}

.story-card img {
    max-width: 30%;
    border-radius: 10px;
}

.story-card h3 {
    font-size: 1.5rem;
    margin: 10px 0;
}

.story-card p {
    font-size: 1rem;
    color: #555;
}

/* Slick-specific styles */
.slick-prev,
.slick-next {
    z-index: 1;
    color: #333;
}

.slick-dots {
    bottom: -30px;
}

.slick-dots li button:before {
    font-size: 10px;
    color: #333;
}

/* Responsive styles */
@media (max-width: 1200px) {
    .slick-slider {
        max-width: 1000px;
    }

    .story-card {
        padding: 15px;
    }
}

@media (max-width: 992px) {
    .slick-slider {
        max-width: 800px;
    }

    .success-stories h2 {
        font-size: 2rem;
    }

    .story-card h3 {
        font-size: 1.25rem;
    }

    .story-card p {
        font-size: 0.95rem;
    }
}

@media (max-width: 768px) {
    .slick-slider {
        max-width: 600px;
    }

    .success-stories h2 {
        font-size: 1.75rem;
    }

    .story-card h3 {
        font-size: 1.1rem;
    }

    .story-card p {
        font-size: 0.9rem;
    }
}

@media (max-width: 576px) {
    .slick-slider {
        max-width: 400px;
    }

    .success-stories h2 {
        font-size: 1.5rem;
    }

    .story-card h3 {
        font-size: 1rem;
    }

    .story-card p {
        font-size: 0.85rem;
    }
}

@media (max-width: 480px) {
    .slick-slider {
        max-width: 300px;
    }

    .success-stories h2 {
        font-size: 1.3rem;
    }

    .story-card h3 {
        font-size: 0.95rem;
    }

    .story-card p {
        font-size: 0.8rem;
    }

    .story-card img {
        width: 20%;
    }
}
