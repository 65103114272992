/* General Header Styles */
.headerm {
  background-color: #333;
  color: #fff;
  padding: 10px 0;
  position: relative;
}

.header.scrolledm {
  background-color: #222;
}

.header__wrapperm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.header__logom img {
  max-height: 50px;
}

/* Navigation Styles */
.navm {
  display: flex;
}

.nav__wrapperm {
  display: flex;
  gap: 20px;
}

.nav__dropdownm {
  position: relative;
}

.nav__dropdown_titlem {
  cursor: pointer;
  padding: 10px 15px;
  transition: background 0.3s ease;
}

.nav__dropdown_titlem:hover {
  background-color: #444;
}

.navContent-wrapm {
  display: none;
  position: absolute;
  background: #fff;
  color: #333;
  padding: 20px;
  z-index: 1000;
}

.navContent-wrapm.active {
  display: block;
}

.navContent-itemm {
  color: #333;
  text-decoration: none;
  padding: 10px;
  transition: background 0.3s ease;
}

.navContent-itemm:hover {
  background: #f0f0f0;
  color: #000;
  text-decoration: underline;
}

.navLinkm {
  color: inherit;
  text-decoration: none;
}

.navLinkm:hover {
  color: #ddd;
}

/* Hamburger Menu */
.hamburgerm {
  display: none;
  cursor: pointer;
  font-size: 24px;
}

/* Grid Layout for Submenu on Large Screens */
@media (min-width: 769px) {
  .nav__dropdownm:hover .navContent-wrapm {
    display: block;
  }

  .navContent-wrapm {
    min-width: 200px; 
  }

  .navContentm.mod-row4m {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .navContentm.mod-row6m {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }

  .navContentm.mod-row7m {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }

  .navContentm.mod-columnm {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

/* Mobile Styles */
@media (max-width: 768px) {
  .navm {
    display: none;
    position: absolute;
    top: 60px;
    right: 0;
    background: #333;
    width: 100%;
    padding: 10px;
  }

  .navm.nav--openm {
    display: block !important;
    z-index: 99999999;
  }

  .nav__wrapperm {
    flex-direction: column;
  }

  .hamburgerm {
    display: block;
  }
}
