.banner1 {
  position: relative;
  width: 100%;
  height: 100vh;
  background: url('../../../../../../../public/assets/images/websiteimages/trainingbanner.png') no-repeat center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 0 5%;
  box-sizing: border-box;
}

.banner1::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.banner-text1 {
  position: relative;
  z-index: 1;
  width: 50%;
  color: white;
  margin-right: 5%;
  padding: 1rem;
}

/* .trainn{
  font-size: 40px !important;
} */
.banner-form1 {
  position: relative;
  z-index: 1;
  width: 30%;
  background: white;
  padding: 2rem;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
 height: 500px;
 margin-top: 90px;
}

.banner-form1 form {
  display: flex;
  flex-direction: column;
}

.banner-form1 h2 {
  /* margin-bottom: 0.1rem; */
  color: black;
  margin-bottom: 20px;
}

.banner-form1 input,
.banner-form1 textarea {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: none;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid grey;
}

.banner-form1 button {
  padding: 0.5rem;
  border: none;
  background: #003e95;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.banner-form1 button:hover {
  background: black;
}

.message {
  margin-top: 10px;
  color: green; /* or red for error messages */
  font-size: 14px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .banner1 {
    
    flex-direction: column;
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    text-align: center;
    padding: 0 2rem;
    height: 100%;
  }

  .banner-text1 {
   
    width: 100%;
    margin: 0;
    padding: 1rem 0;
    margin-top: 100px;
  }

  .banner-form1 {
    width: 100%;
    margin: rem 0 0 0; /* Margin top to separate form from text */
    margin-bottom: 10px;
  }
}

.banner-form1 textarea{
  resize: none; 
}