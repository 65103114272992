/* AboutSaasverse.css */

.about-saasverse-container {
  padding: 20px;
}

.centered-heading {
  text-align: center;
  /* font-size: 40px !important; */
}

.about-saasverse-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px; /* Adjust as needed */
  margin: auto;
  margin-top: 30px;
}

.about-saasverse-image {
  flex: 1;
  
  align-self: center;
  
}
.about-saasverse-image img {
  width: 80%; /* Ensure image fills its container */
  height: auto;
  padding-left: 50px;
  border-radius: 25px; /* Adjust border radius as needed */
 
}

.about-saasverse-text {
  flex: 1;
  margin-left: 20px; /* Space between image and text */
  padding: 20px; /* Add padding to text container */
}

.about-saasverse-text p {
  text-align: justify; /* Justify text */
  line-height: 1.6; /* Adjust line height as needed */
}

.about-saasverse-list {
  margin-top: 20px;
  padding-left: 20px;
  list-style: none;
}

.about-saasverse-list li {
  margin-top: 10px;
  position: relative;
  opacity: 0; /* Initially hide list items */
  transform: translateX(-20px); /* Initial position for animation */
  transition: opacity 0.5s ease, transform 0.5s ease; /* Animation transition */
}

.about-saasverse-list li.show {
  opacity: 1; /* Show list item when in view */
  transform: translateX(0); /* Move to original position */
}

.about-saasverse-list li .tick-mark {
  position: absolute;
  left: -20px; /* Adjust to align with text */
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .about-saasverse-content {
    flex-direction: column; /* Stack items vertically on smaller screens */
    align-items: center; /* Center items horizontally */
  }

  .about-saasverse-text {
    margin-left: 0; /* Remove left margin on text for better centering */
    margin-top: 20px; /* Add top margin */
  }
}




