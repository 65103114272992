/* Contact Header Container */
.contact-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 50px;
    background: rgb(21,108,156);
    background: radial-gradient(circle, rgba(21,108,156,1) 0%, rgba(0,0,0,1) 100%);
    width: 100%;
  
   
  }
  
  /* Available Text with Glowing Circle */
  .available-text {
    position: relative;
    font-size: 24px;
    font-weight: bold;
    color: white;
    margin: 0;
   
  }
  
  .available-text::before {
    content: '';
    position: absolute;
    left: -30px; /* Adjust based on spacing needs */
    top: 50%;
    transform: translateY(-50%);
    width: 15px; /* Circle size */
    height: 15px; /* Circle size */
    background-color: green;
    border-radius: 50%;
    box-shadow: 0 0 20px #6f9;

  }
  
  /* Mobile Number */
  .mobile-number {
    font-size: 24px;
    font-weight: 500;
    color: white;
    margin-right: 70px;
    
  }
  
  /* Responsive Design */
  @media (max-width: 600px) {
    .contact-header {
      flex-direction: flex;
      text-align: center;
      justify-content: space-between;
      align-items: center;
      padding: 20px 20px;
      background: rgb(21,108,156);
      background: radial-gradient(circle, rgba(21,108,156,1) 0%, rgba(0,0,0,1) 100%);
      width: 100%;
    }
  
    .available-text {
      /* margin-bottom: 10px; */
      font-size: 17px !important;
      margin-left: 10px;
    }
  
    .mobile-number {
      font-size: 17px !important;
      margin-right: 35px !important;
    }
 
    .available-text::before {
      left: -20px; /* Adjust for smaller screens */
      width: 12px; /* Smaller circle size */
      height: 12px; /* Smaller circle size */
    }
  }
  