/* General Styling for the CTA Panel */
.cta-panel {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background: linear-gradient(90deg, rgba(0,107,149,1) 0%, rgba(0,0,0,1) 100%);
    color: white;
    position: relative;
    height: 30vh;
    min-height: 150px; /* Minimum height for very small screens */
    width: 100%;
    overflow: hidden;
  }
  
  .cta-panel-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    max-width: 1200px;
  }
  
  .left-col,
  .right-col {
    display: flex;
    align-items: center;
  }
  
  .col-inner {
    padding: 10px 20px;
  }
  
  /* Text Styling */
  .left-col p {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 300;
  }
  
  .left-col p,
  .right-col a {
    color: white;
  }
  
  /* Button Styling */
  .right-col a {
    display: inline-block;
    padding: 10px 20px;
    background-color: #006b95; /* Matching the gradient start color */
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 700;
    transition: background-color 0.3s;
  }
  
  .right-col a:hover {
    background-color: #004d70; /* Darker shade for hover effect */
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .cta-panel-inner {
      flex-direction: column;
      text-align: center;
    }
  
    .left-col,
    .right-col {
      width: 100%;
      justify-content: center;
      margin: 10px 0;
    }
  
    .right-col a {
      font-size: 0.9rem;
      padding: 8px 16px;
    }
  }
  