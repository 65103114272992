/* Why.css */

/* Main Section Styling */
#flip-cards-block_ce0364fe2105b984e397a9bd656ecd9d {
    padding: 60px 20px; /* Section padding */
    background-color: #f5f5f5; /* Light grey background */
    text-align: center; /* Center align text */
}

/* Title Styling */
.title-with-lines {
    position: relative;
    display: inline-block;
    font-size: 36px; /* Title font size */
    color: #333; /* Title text color */
    margin-bottom: 40px; /* Bottom margin */
    padding-bottom: 10px; /* Padding for lines */
}

.title-with-lines::before,
.title-with-lines::after {
    content: '';
    position: absolute;
    width: 50px; /* Line width */
    height: 3px; /* Line height */
    background-color: #006b95; /* Line color */
    top: 100%; /* Position at bottom */
}

.title-with-lines::before {
    left: 50%; /* Align left line to the center */
    transform: translateX(-100%);
    margin-right: 10px; /* Margin from the title */
}

.title-with-lines::after {
    right: 50%; /* Align right line to the center */
    transform: translateX(100%);
    margin-left: 10px; /* Margin from the title */
}

/* Flip Cards Container */
.flip-cards-container {
    display: flex; /* Display as flex */
    flex-wrap: wrap; /* Wrap cards */
    justify-content: space-between; /* Space between cards */
    gap: 20px; /* Gap between cards */
    padding: 0 10px; /* Padding for section */
    align-items: stretch; /* Align items to stretch */
}

/* Flip Card Outer */
.flip-card-outer {
    perspective: 1000px; /* Perspective for 3D effect */
    flex: 1 1 30%; /* Flex basis to 30% for 3 cards in a row */
    max-width: 30%; /* Max width to 30% for cards */
    min-width: 250px; /* Minimum width for cards */
    display: flex; /* Flex for equal height */
    align-items: stretch; /* Align items to stretch */
}

/* Flip Card */
.flip-card {
    width: 100%; /* Full width */
    height: 500px; /* Fixed height */
    transition: transform 0.6s; /* Smooth transition */
    transform-style: preserve-3d; /* Preserve 3D transform */
    position: relative; /* Position relative */
    display: flex; /* Flex layout */
    align-items: stretch; /* Align items to stretch */
    flex-direction: column; /* Column layout */
}

.flip-card:hover {
    transform: rotateY(180deg); /* Rotate on hover */
}

/* Flip Card Body */
.flip-card-body {
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    position: relative; /* Relative position */
    flex: 1; /* Grow to fill height */
}

/* Flip Card Front and Back */
.flip-card-front, .flip-card-back {
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    position: absolute; /* Absolute position */
    top: 0; /* Top alignment */
    left: 0; /* Left alignment */
    display: flex; /* Flex layout */
    justify-content: center; /* Center align */
    align-items: center; /* Center align */
    flex-direction: column; /* Column layout */
    backface-visibility: hidden; /* Hide backface */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    background-color: white; /* Background color */
    transition: box-shadow 0.3s ease; /* Smooth shadow transition */
}

.flip-card-front:hover, .flip-card-back:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}

.flip-card-back {
    background-color: #006b95; /* Back background color */
    color: white; /* Back text color */
    padding: 20px; /* Padding for content */
    transform: rotateY(180deg); /* Initial rotation */
}

/* Flip Card Front */
.flip-card-front {
    z-index: 2; /* Front z-index */
    transform: rotateY(0deg); /* Initial rotation */
}

.flip-card-front img {
    width: 80px; /* Image width */
    height: 80px; /* Image height */
    margin-bottom: 20px; /* Bottom margin */
}

.flip-card-front h2 {
    font-size: 20px; /* Front title font size */
    color: #333; /* Front title text color */
}

.flip-card-front h3 {
    font-size: 18px; /* Front subtitle font size */
    color: #006b95; /* Front subtitle text color */
    margin-bottom: 10px; /* Bottom margin */
}

/* Responsive Design */
@media (max-width: 1024px) {
    .flip-card-outer {
        max-width: 45%; /* Adjust width for smaller screens */
    }

    .flip-card {
        height: 450px; /* Adjust height */
    }
}

@media (max-width: 768px) {
    .flip-card-outer {
        max-width: 100%; /* Full width for smaller screens */
    }

    .flip-card {
        height: 400px; /* Adjust height */
    }
}

@media (max-width: 480px) {
    .title-with-lines {
        font-size: 28px; /* Smaller font size for title */
    }

    .flip-card {
        height: 350px; /* Smaller height */
    }

    .flip-card img {
        width: 60px; /* Smaller image size */
        height: 60px; /* Smaller image size */
    }

    .flip-card h2 {
        font-size: 18px; /* Smaller font size */
    }

    .flip-card h3 {
        font-size: 16px; /* Smaller font size */
    }

    .flip-card-back p {
        font-size: 14px; /* Smaller font size */
    }
}
