.alllll {
  background-color: white;
  /* padding: 20px; */
}

.slideCnnll {
  position: relative;
  perspective: 1000px;
  transform-style: preserve-3d;
  width: 362px;
  height: 472px;
  margin: 0 auto;
}

.slidennll {
  width: 362px;
  height: 372px;
  transition: transform 500ms ease 0s, opacity 500ms ease 0s, visibility 500ms ease 0s;
  position: absolute;
  top: 0;
  border-radius: 12px;
  margin-top: 50px;
}

.reflectionnnll {
  /* position: absolute;
  width: 100%;
  height: 60px;
  bottom: -60px;
  border-radius: 12px;
  transition: transform 500ms ease 0s, opacity 500ms ease 0s, visibility 500ms ease 0s; */
}

.slidennll {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sliderContentnnll {
  display: flex;
  flex-direction: column;
  color: white;
  padding: 30px;
  align-items: flex-start;
}

.sliderContentnnll h2 {
  margin: 16px 0;
  font-size: 21px;
}

.sliderContentnnll p {
  margin: 0;
  margin-bottom: 16px;
  font-size: 16px;
}

.centernnll {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.btnsll {
  /* padding-top: 60px; */
  padding-bottom: 40px;
  text-align: center;
}

.btnsll .btnll {
  cursor: pointer;
}

.btnsll .btnll:last-child {
  margin-left: 40px;
}

.service-headingll {
  color: black;
  text-align: center;
  padding-top: 30px;
  font-size: 40px;
}

.sideimage {
  width: 100%;
  text-align: center;
}

.sideimage img {

  width: 85px;
  height: 53px;
  
}

/* Media queries for responsive adjustments */
@media (max-width: 768px) {
  .slideCnnll {
    width: 300px;
    height: 400px;
  }

  .slidennll {
    width: 300px;
    height: 300px;
    margin-top: 30px;
  }

  .reflectionnnll {
    height: 50px;
    bottom: -50px;
  }

  .sliderContentnnll h2 {
    font-size: 18px;
  }

  .sliderContentnnll p {
    font-size: 14px;
  }

  .service-headingll {
    font-size: 35px;
  }
}

@media (max-width: 480px) {
  .sliderContentnnll {
    justify-content: center;
    height: 320px;
}

  .slideCnnll {
    width: 240px;
    height: 350px;
    margin-top: 20px;
  }

  .btnsll {
  
    margin-right: 30px;
}
  .slidennll {
    width: 240px;
    height: auto;
    margin-top: 20px;
  }

  .reflectionnnll {
    height: 40px;
    bottom: -40px;
  }

  .sliderContentnnll h2 {
    font-size: 16px;
  }

  .sliderContentnnll p {
    font-size: 12px;
  }

  .service-headingll {
    font-size: 28px;
  }

  .btnsll .btnll {
    margin-left: 20px;
  }
  
  .sideimage img {
    width: 70px;
    height: 45px;
  }
}














@media (max-width: 480px) {
  .slidennll {
      width: 100%;
      height: auto;
      margin-top: 20px;
      margin: auto;
  }
}
@media (max-width: 480px) {
  .slideCnnll {
      width: 100vw;
      height: 350px;
      margin: auto;
      margin-top: 20px;
  }}