/* banner.css */

/* Styles for the hero section */
.hero {
  position: relative;
  height: 100vh; /* Adjust height as needed */
  background-color: #f5f5f5; /* Fallback background color */

}

/* Styles for the hero container */
.hero-container {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Adjust spacing between elements */
  height: 100%;
  padding: 0 20px; 
  
}

/* Styles for the hero content */
.hero__content {
  max-width: 600px; /* Adjust maximum width as needed */
  text-align: left;
 
}

.hero__content_title {
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 20px;
  padding-top: 100px;
}

.hero__content_text {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.hero__content_button {
  cursor: pointer;
  display: inline-block;
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 4px;
  background-color: #006b95;
  color: #fff;
  transition: background-color 0.3s ease;
}

.hero__content_button:hover {
  background-color: #005580;
}

.hero__content_logos {
  margin-top: 30px;
}

.hero__content_logos-item {
  height: 40px; /* Adjust height as needed */
  margin-right: 10px;
}

/* Styles for the hero image */
.hero__image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero__image img {
  max-width: 100%;
  height: auto;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .hero {
   
    height: auto; /* Adjust height as needed */
padding-bottom: 30px;
width: 100%;

  }
  .hero-container {
      flex-direction: column;
      text-align: center;
     
  }

  .hero__content {
      max-width: 100%;
      width: 100%;
      /* margin-left: 0; */
      padding: 0 20px;
      text-align: center;
    
  }

  .hero__content_title {
      font-size: 1.9rem;
      padding-top: 20px;
     width: 100%;
     
   
  }

  .hero__content_text {
      margin-bottom: 20px;
  }

  .hero__content_button {
      font-size: 0.9rem;
  }

  .hero__content_logos {
      margin-top: 20px;
  }
}




