/* Main container styling */
.expertise-home-containernew {
    display: flex;
    flex-direction: row; /* Default layout for larger screens */
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 20px;
    gap: 40px;
    background-color: white; 
  }
  
  /* Carousel container on the left */
  .carousel-containernew {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 50%; /* Carousel on the left side */
  }
  
  /* Info section container on the right */
  .info-containernew {
    flex: 1;
    max-width: 50%; /* Content on the right side */
    text-align: left;
  }
  
  .info-containernew h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #003e95;
  }
  
  .info-containernew p {
    font-size: 16px;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Read More button */
  .info-read-more-buttonnew {
    display: inline-block;
    padding: 10px 20px;
    background-color: #003e95;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .info-read-more-buttonnew:hover {
    background-color: black;
    transition: background-color 0.3s ease-in-out;
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    .expertise-home-containernew {
      flex-direction: column; /* Stack elements vertically */
      height: auto; /* Adjust height for vertical stacking */
      gap: 70px; /* Reduce gap for smaller screens */
      padding: 10px; /* Adjust padding */
      margin-top: 60px  ;
    }
  
    .carousel-container, 
    .info-containernew {
      max-width: 100%; /* Take full width */
      text-align: center; /* Center text for better appearance */
    }
  
    .info-containernew h2 {
      font-size: 20px; /* Reduce font size for smaller screens */
    }
  
    .info-containernew p {
      font-size: 14px; /* Reduce paragraph size */
    }
  
    .info-read-more-buttonnew {
      padding: 8px 16px; /* Reduce button size */
      font-size: 12px; /* Adjust button font size */
    }
  }
  
  /* Extra Small Devices */
  @media (max-width: 480px) {
    .info-containernew h2 {
      font-size: 18px;
    }
  
    .info-containernew p {
      font-size: 12px;
    }
  
    .info-read-more-buttonnew {
      padding: 6px 12px;
      font-size: 10px;
    }
  }
  