.trusted-partner {
    padding: 20px;
    text-align:center;
    
  }
  
  
  .trusted-partner h2 {
    margin-bottom: 20px;
    font-size: 40px;
    font-weight: bold;
    /* font-family: "CerebriSans",sans-serif; */
 
   
  }
  
  .trusted-partner__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Responsive grid */
    gap: 20px;
  }
  
  .trusted-partner__item {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .trusted-partner__item img {
    max-width: 100%;
    max-height: 70px; /* Limit height */
    object-fit: contain;
  }
  
  /* Responsive adjustments */
  @media (min-width: 768px) {
    .trusted-partner h2 {
      margin-bottom: 20px;
      font-size: 40px;
      font-weight: bold;
     
   
     
    }

    .trusted-partner__grid {
      grid-template-columns: repeat(4, 1fr); /* 4 logos per row on larger screens */
    }
  }
  
  .trusted-partner__item:last-child {
    grid-column: 1 / -1; /* Span all columns */
    justify-self: center;
    padding: 10px 70px;
  }

  @media (max-width: 768px) {
    .trusted-partner h2 {
      margin-bottom: 20px;
      font-size: 22px;
      font-weight: 500;
     
   
     
    }}