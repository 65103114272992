/* JobOpenings.css */

/* Job Openings Container */
.job-openings {
  text-align: center; /* Center align heading */
  padding: 40px 20px; /* Padding around the content */
  /* background-color: red; */
  /* width: 100vw; */
}

/* Job Openings Title */
.job-openings-title {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

/* Job List */
.job-list {
  width: 90vw; /* Take 90% width of the parent container */
  max-width: 1000px; /* Maximum width for responsiveness */
  margin: 0 auto; /* Center align the job list */
}

/* Job Item */
.job-item {
  margin-bottom: 20px; /* Spacing between job items */
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  text-align: left;
}

/* Apply Button */
.apply-button {
  margin-left: auto; /* Move button to the right */
  padding: 17px 16px;
  background-color: #003e95;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 15px;
  
}

.apply-button:hover {
  background-color: black;
}

/* Job Details Container */
.job-details {
  display: flex;
  align-items: center; /* Center vertically */
}

.job-details p {
  margin-right: 10px; /* Remove all margins */
}

.job-details p span {
  margin-right: 5px; /* Add spacing between span and text */
}
