.client-info-containerb {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  height: 100%;
 
}

.client-backgroundb, .client-reasonb {
  flex: 1;
  padding: 20px 80px;
  box-sizing: border-box;
 padding-bottom: 50px;
}

.client-backgroundb {
  background-color: black;
  z-index: 2;
  text-align: center;
 
 
}

.client-reasonb {
  padding-left: 40px;
}

.client-backgroundb h2, .client-reasonb h2 {
  margin-top: 60px;
  margin-bottom: 20px;
}

.client-backgroundb h2 {
  color: white;
}

.client-backgroundb p {
  text-align: justify;
  color: white;
  line-height: 2.1;
  width: 60%;
  text-align: center;
  margin: auto;
}

.client-reasonb p {
  text-align: justify;
  line-height: 1.9;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .client-backgroundb, .client-reasonb {
    padding: 20px 40px;
  }
}

@media (max-width: 992px) {
  .client-info-containerb {
    flex-direction: column;
  }
  
  .client-backgroundb, .client-reasonb {
    padding: 20px;
    margin: 10px 0;
  }
}

@media (max-width: 768px) {
  .client-backgroundb, .client-reasonb {
    padding: 20px;
  }
  
  .client-backgroundb h2, .client-reasonb h2 {
    font-size: 1.5em;
  }
  
  .client-backgroundb p, .client-reasonb p {
    font-size: 1em;
  }
  .client-backgroundb p {
  text-align:unset;
    width: 90%;
   
  }
}

@media (max-width: 576px) {
  .client-backgroundb, .client-reasonb {
    padding: 20px;
  }
  
  .client-backgroundb h2, .client-reasonb h2 {
    font-size: 2.0em;
  }
  
  .client-backgroundb p, .client-reasonb p {
    font-size: 1.0em;
  }
}
