/* AboutUsBanner.css */

/* Container styles */
.about-us-banner {
  position: relative;
  width: 100%;
  height: 100vh; /* Full viewport height */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.banner-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.banner-container picture,
.banner-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Overlay styles */
.banner-content-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 20px;
  box-sizing: border-box;
}

.banner-content-overlay h1 {
  font-size: 3em;
  margin-bottom: 10px;
}

.banner-content-overlay p {
  font-size: 1.2em;
  max-width: 800px;
  margin: 0 auto;
}

/* Responsive design */

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .banner-content-overlay {
    padding: 10px;
  }

  .banner-content-overlay h1 {
    font-size: 1.8em;
  }

  .banner-content-overlay p {
    font-size: 0.9em;
    max-width: 90%;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .banner-content-overlay h1 {
    font-size: 2.2em;
  }

  .banner-content-overlay p {
    font-size: 1em;
    max-width: 90%;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .banner-content-overlay h1 {
    font-size: 2.5em;
  }

  .banner-content-overlay p {
    font-size: 1.1em;
    max-width: 80%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .banner-content-overlay h1 {
    font-size: 2.8em;
  }

  .banner-content-overlay p {
    font-size: 1.2em;
    max-width: 70%;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .banner-content-overlay h1 {
    font-size: 3em;
  }

  .banner-content-overlay p {
    font-size: 1.2em;
    max-width: 60%;
  }
}
