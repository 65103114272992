/* AppExchangeReadyBanner.css */

.cnt_bannera {
    display: flex;
    justify-content: center;
    padding: 40px 20px;
    background-color: white; /* Light background color */
    border-radius: 10px;
    /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
    overflow: hidden;
    margin: 20px 0;
  }
  
  .banner__containera {
    width: 100%;
    max-width: 1200px; /* Limit max width */
    padding: 0 15px; /* Padding for content */
  }
  
  .banner__headinga {
    font-size: 40px !important;
    color: black;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center; /* Align heading to the left */
   
  }
  
  .banner__text-wrappera {
    /* display: flex; */
    justify-content: space-between;
    align-items: center;
  }
  
  @media (max-width: 768px) {
    .banner__text-wrappera {
      flex-direction: column;
      align-items: center;
    }
  }
  
  .banner__text-contenta {
    flex: 0 0 60%; /* Occupies 60% width */
    text-align: left;
  }
  
  .banner__text-contenta p {
    font-size: 18px;
    color: #555;
    line-height: 1.6;
  }
  
  .banner__button-wrappera {
    flex: 0 0 30%; /* Occupies 30% width */
    display: flex;
    justify-content: flex-end;
    align-items: center;
   
  }
  
  @media (max-width: 768px) {
    .banner__button-wrappera {
      justify-content: center;
      width: 100%;
      margin-top: 20px;
    }
  }
  
  .banner__buttona {
    font-size: 18px;
    color: #fff;
    background-color: #006b95;
    border: 2px solid #006b95;
    padding: 12px 30px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .banner__buttona:hover {
    background-color: #00507a;
    color: #e6e6e6;
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .banner__headinga {
      font-size: 24px;
    }
  
    .banner__text-contenta p {
      font-size: 16px;
    }
  
    .banner__buttona {
      font-size: 16px;
      padding: 10px 25px;
    }
  }
  
  @media (max-width: 768px) {
    .banner__headinga {
      font-size: 22px;
    }
  
    .banner__text-contenta p {
      font-size: 14px;
    }
  
    .banner__buttona {
      font-size: 14px;
      padding: 8px 20px;
    }
  }
  
  @media (max-width: 480px) {
    .banner__headinga {
      font-size: 20px;
    }
  
    .banner__text-contenta p {
      font-size: 13px;
    }
  
    .banner__buttona {
      font-size: 13px;
      padding: 7px 15px;
    }
  }
  