.saasverse-partner {
    position: relative;
    width: 90%;
    height: 16vh;
    margin: 0 auto;
    background:#032e61;
    padding: 20px;
    margin-top: -50px;
    z-index: 2;
    border-radius: 40px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .saasverse-inner{
    display:flex;
  }
  
  .word {
    padding: 10px;
    color: #032e61;
    font-weight: bold;
    font-size: 1.5rem;
    border-radius: 5px;
  }
  .con{
    color: white;
    text-align: center;
    text-justify: center;

  }