/* Container styles */
.privacy-container {
    margin: 20px;
    padding: 20px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: white;
    font-family: Arial, sans-serif;
  }
  
  /* Headings */
  .privacy-container h1, .privacy-container h2 {
    color: #333;
  }
  
  .privacy-container h1 {
    font-size: 2em;
    margin-bottom: 0.5em;
    border-bottom: 2px solid #ddd;
    padding-bottom: 0.5em;
  }
  
  .privacy-container h2 {
    font-size: 1.5em;
    margin-top: 1em;
    margin-bottom: 0.5em;
    color: #444;
    border-bottom: 1px solid #eee;
    padding-bottom: 0.25em;
  }
  
  /* Paragraphs */
  .privacy-container p {
    line-height: 1.6;
    color: #555;
    margin-bottom: 1em;
  }
  
  /* Links */
  .privacy-container a {
    color: #0066cc;
    text-decoration: none;
  }
  
  .privacy-container a:hover {
    text-decoration: underline;
  }
  
  /* Responsive design */
  @media (max-width: 600px) {
    .privacy-container {
      padding: 10px;
      margin: 10px;
    }
  
    .privacy-container h1 {
      font-size: 1.5em;
    }
  
    .privacy-container h2 {
      font-size: 1.2em;
    }
  }
  