/* Default styles */
.info-container {
    margin-top: 5%;
    display: flex;
    align-items: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .info-image {
    flex: 1;
    margin-left: 30px;
  }
  
  .info-image img {
    width: 100%;
    max-width: 100%;
    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  
  .info-text {
    flex: 1;
    
  }
  
  .info-text h2 {
   

  }
  
  .info-text p {
    font-size: 16px;
    line-height: 1.6;
  }
  
  /* Media query for mobile devices */
  @media (max-width: 768px) {
    .info-container {
      flex-direction: column;
      align-items: stretch;
    }
  
    .info-image {
      margin-right: 0;
      margin-bottom: 20px;
    }
    .info-image img {
        width: 100%;
        max-width: 100%;
        border-top-left-radius: 50px;
        border-bottom-right-radius: 50px;
      }
  }
  
  .title-bordered {
    text-align: center; /* Center align the content */
    padding: 20px; /* Add padding for better spacing */
  }

  .title-with-lines {
    font-size: 1.5rem; /* Adjust font size */
    line-height: 1.2; /* Set line height */
    margin: 0; /* Remove default margins */
  }

  /* Adjustments for smaller screens */
  @media only screen and (max-width: 600px) {
    .title-with-lines {
      font-size: 1.2rem; /* Reduce font size for smaller screens */
    }
  }