/* Container styles */
.containert {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Flexible grid layout */
  gap: 1rem; /* Gap between columns */
  padding: 1rem;
  background-color: black; /* Background color */
  border-radius: 30px; /* Rounded corners */
  min-height: 400px; /* Minimum height */
  width: 95%; /* Width of container */
  max-width: 1250px; /* Maximum width */
  margin: 3% auto; /* Centering the container */
}

/* Column styles */
.columnt {
  padding: 3rem;
  border-radius: 8px; /* Rounded corners */
}

/* Column header styles */
.columnt h2 {
  font-size: 2.5rem;
  color: white; /* Text color */
  margin-bottom: 0.5rem;
}

/* Column subheader styles */
.columnt h4 {
  font-size: 1rem;
  font-weight: 300;
  color: white; /* Text color */
  margin-bottom: 1rem;
}

/* Column paragraph styles */
.columnt p {
  font-size: 1rem;
  line-height: 1.5;
  color: white; /* Text color */
  margin-bottom: 0.5rem;
  font-weight: 300;
}

/* Responsive styles */
@media (max-width: 768px) {
  .containert {
      grid-template-columns: 1fr; /* Single column layout on small screens */
      min-width: 0; /* Remove minimum width constraint */
      padding: 0.5rem; /* Reduce padding */
  }

  .columnt {
      padding: 1.5rem; /* Adjust padding for smaller screens */
      margin-bottom: 1rem; /* Space between columns when stacked */
  }

  .columnt h2 {
      font-size: 2rem; /* Adjust font size for smaller screens */
  }
}
