.client-info-container {
    display: flex;
    width: 90%;
    margin: auto;
    overflow: hidden;
    position: relative;
 
  }
  
  .headingss {
    width: 30%;
    /* background-color: #f4f4f4; */
    /* border-right: 1px solid #ccc; */
    position: fixed; /* Keep headings fixed */
    top: 0;
    height: 100%; 
    /* padding: 20px; */
    box-sizing: border-box;
    display: flex;
    align-items: center;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  
  .headingg {
    font-size: 3.0em;
    color: #003e95;
    opacity: 1; /* Always show active heading */
    font-weight: 500;
   
  }
  
  .contents {
    width: 70%;
    margin-left: 30%; /* Offset for fixed headings */
    padding: 20px;
    box-sizing: border-box;
   
  }
  
  .content-section {
    height: 100vh; /* Ensure each section takes full viewport height */
    display: flex;
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    transition: opacity 0.6s ease, transform 0.6s ease; /* Smooth transition */
    opacity: 0.5; /* Initially dim all sections */
    
  }
  
  .content-section.active {
    opacity: 1; /* Highlight active section */
    transform: translateY(0); /* Ensure active section is in view */
  }
  
  .contentg {
   
    color: #333;
    line-height: 1.9;
    text-align:justify;
  }
  