/* Why.css */

/* Container Styling */
.info-container {
    display: flex; 
    align-items: center; 
    justify-content: space-between; 
    padding: 40px 20px; 
    background-color: white; 
    border-radius: 10px; 
    /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);  */
    gap: 20px; 
    margin: 20px 0; 
    overflow: hidden; 
    flex-wrap: wrap; /* Allow wrapping */
  
}

/* Image Container */
.info-image {
    flex: 1 1 45%; 
    max-width: 65%; 
    display: flex; 
    justify-content: center; 
    overflow: hidden; 
    transition: transform 0.5s ease-in-out; 
}

.info-image img {
    width: 100%; 
    height: auto; 
  
    /* box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);  */
    object-fit: cover; 
    transition: transform 0.5s ease-in-out; 
}

/* Scale up on hover */
.info-image:hover img {
    transform: scale(1.05); 
}

/* Text Container */
.info-text {
    flex: 1 1 50%; 
    max-width: 50%; 
    color: #333; 
    text-align: left; 
    padding: 20px; /* Padding for better spacing */
}

.info-text h2 {
    font-size: 40px; 
    color: #006b95; 
    margin-bottom: 20px; 
    
}

.info-text p {
    font-size: 16px; 
    line-height: 1.6; 
    color: #555; 
    margin-bottom: 15px; 
}

.info-text b {
    font-size: 18px; 
    color: #006b95; 
}

/* Responsive Design */
@media (max-width: 1024px) {
    .info-container {
        flex-direction: column; 
        text-align: center; 
    }

    .info-image, .info-text {
        max-width: 100%; 
    }

    .info-image img {
        width: 80%; 
    }

    .info-text h2 {
        font-size: 24px; 
    }

    .info-text p {
        font-size: 14px; 
    }
}

@media (max-width: 768px) {
    .info-text h2 {
        font-size: 27px !important;
        font-weight: 600 !important; 
    }

    .info-text p {
        font-size: 14px; 
    }
}

@media (max-width: 480px) {
    .info-container {
        padding: 20px 10px; 
    }

    .info-image img {
        width: 100%; /* Full width on smaller screens */
    }

    .info-text h2 {
        font-size: 20px; 
    }

    .info-text p {
        font-size: 13px; 
    }
}
