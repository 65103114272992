#servicess {
  padding: 50px 0; /* Vertical padding */
  background-color: #f5f5f5; /* Light grey background */
}

.cnts {
  max-width: 1200px; /* Maximum width of the content */
  margin: auto; /* Center align content */
  padding: 0 20px; /* Horizontal padding */
}

/* h2 {
  text-align: center; 
  font-size: 32px; 
  color: #333; 
  margin-bottom: 40px; 
} */

.reasons__wrappers {
  display: flex; /* Display items in a row */
  flex-wrap: wrap; /* Allow items to wrap */
  gap: 20px; /* Gap between items */
  justify-content: space-between; /* Space out items evenly */
}

.reasons__items {
  background-color: white; /* Background color */
  border-radius: 10px; /* Rounded corners */
  padding: 20px; /* Padding for content */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  flex: 1; /* Allow items to grow */
  min-width: calc(50% - 20px); /* Ensure two items per row with gap */
  max-width: calc(50% - 20px); /* Ensure two items per row with gap */
  box-sizing: border-box; /* Include padding in width calculation */
  position: relative; /* Position relative for pseudo-element */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effects */
}

.reasons__items:hover {
  transform: translateY(-5px); /* Slight lift on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}

.reasons__item_figures {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 6px; /* Figure width */
  height: 100%;
  background-color: #006b95; /* Figure color */
  border-radius: 10px 0 0 10px; /* Rounded left corners */
}

.reasons__item_contents {
  padding-left: 20px; /* Padding to offset figure */
}

.reasons__item_titles {
  font-size: 24px; /* Title font size */
  color: #333; /* Title text color */
  margin-bottom: 10px; /* Bottom margin */
}

.reasons__item_texts {
  font-size: 16px; /* Text font size */
  color: #666; /* Text color */
  line-height: 1.5; /* Line height for readability */
  margin: 0; /* Reset margin */
}

.reasons__item_texts p {
  margin: 0 0 10px; /* Spacing between paragraphs */
}

/* Responsive Design */
@media (max-width: 768px) {
  .reasons__wrappers {
    flex-direction: column; /* Stack items vertically */
    gap: 30px; /* Increase gap for vertical stacking */
  }
  
  .reasons__items {
    min-width: 100%; /* Full width on smaller screens */
    max-width: 100%; /* Full width on smaller screens */
  }
}
