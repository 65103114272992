.banner1t {
    position: relative;
    width: 100%;
    height: 100vh;
    background: url('../../../../../../public/assets/images/websiteimages/trainingbanner.png') no-repeat center center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    padding: 0 5%;
    box-sizing: border-box;
  }
  
  .banner1t::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
  
  .banner-text1t {
    position: relative;
    z-index: 1;
    width: 50%;
    color: white;
    margin-right: 5%;
    padding: 1rem;
  }
  
  /* .trainn{
    font-size: 40px !important;
  } */
 
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .banner1t {
      
      flex-direction: column;
      align-items: center; /* Center items vertically */
      justify-content: center; /* Center items horizontally */
      text-align: center;
      padding: 0 2rem;
    
    }
  
    .banner-text1t {
     
      width: 100%;
      margin: 0;
      padding: 1rem 0;
     
    }
  
  
  }
  
