.batch-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  margin-top: 60px;
}

.batch-buttons {
  margin-bottom: 1rem;
}

.batch-buttons button {
  margin-right: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  background-color: grey;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  font-size: 1rem;
}

.batch-buttons button.active {
  background-color: #003e95;
}

.batch-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.course-item {
  margin-bottom: 1rem;
}

.course-info {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
}

.course-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.course-title {
  font-weight: bold;
  font-size: 1.2rem;
  color: #003e95;
}

.course-body {
  display: flex;
  align-items: flex-start;
  margin-top: 0.5rem;
  flex-direction: column; /* Stack elements vertically on small screens */
}

.toggle-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 1rem;
  color: #003e95;
  margin-right: 0.5rem;
  align-self: flex-start;
  margin-top: 20px;
}

.course-description {
  padding: 0.5rem;
}

.batch-sublist {
  list-style: none;
  padding: 0;
  margin: 0;
  padding-left: 1rem;
}

.batch-item {
  margin-bottom: 0.5rem;
}

.batch-info {
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 4px;
  background-color: #fff;
}

.batch-info:hover {
  background-color: #f0f0f0;
}

.batch-details-inline {
  padding: 0.5rem;
  border-radius: 4px;
  margin-top: 0.5rem;
  border-top: 1px solid #ddd;
}

/* Responsive styles */
@media (max-width: 768px) {
  .batch-container {
    padding: 15px;
  }

  .batch-buttons button {
    margin-right: 0.5rem;
    padding: 0.4rem 0.8rem;
    font-size: 0.9rem;
  }

  .course-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .course-title {
    font-size: 1.2rem;
    color: #003e95;
  }

  .toggle-button {
    margin-top: 15px;
    font-size: 1.1rem;
  }

  .course-description {
    padding: 0.4rem;
  }

  .batch-info {
    padding: 0.4rem;
  }

  .batch-details-inline {
    padding: 0.4rem;
    margin-top: 0.4rem;
  }
}

@media (max-width: 480px) {
  .batch-container {
    padding: 10px;
  }

  .batch-buttons button {
    margin-right: 0.25rem;
    padding: 0.8rem 0.8rem;
    font-size: 1.1rem;
  }

  .course-title {
    font-size: 1.2rem;
    color: #003e95;
  }

  .toggle-button {
    margin-top: 15px;
    font-size: 1.0rem;
  }

  .course-description {
    padding: 0.3rem;
  }

  .batch-info {
    padding: 0.3rem;
  }

  .batch-details-inline {
    padding: 0.3rem;
    margin-top: 0.3rem;
  }
}
