/* Styles for the button to open the popup */

.popup-background {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999;
}

.popup-container {
    display: flex;
    width: 100%;
    max-width: 90%;
    background: linear-gradient(145deg, black, #333);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.form-container {
    flex: 1;
    padding: 20px;
    color: white;
}

.form-container h2 {
    margin-top: 0;
}

.form-container form {
    display: flex;
    flex-direction: column;
}

.form-container label {
    margin-top: 10px;
}

.form-container input,
.form-container textarea {
    margin-top: 5px;
    padding: 10px;
    border: none;
    border-radius: 4px;
}

.form-container .submit-button {
    margin-top: 20px;
    padding: 10px;
    background-color: #555;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.form-container .submit-button:hover {
    background-color: #777;
}

.image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #111;
}

.image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
