
/* * {
  margin: 0;
  padding: 0;
 
} */



#maingt {
  
  position: relative;
  width: 100%;
  height: 8;
  background-image: url(../../../../../public/assets/images/aaa1.png);
  overflow: hidden;
  
  
}

#topgt {
  position: absolute;
  top: 0%;
  width: 100%;
  height: 50vh;
  background-color: black;
  z-index: 9;
  overflow: hidden;
}

#centergt {
background-image: url(../../../../../public/assets/images/aaa1.png);
}

#bottomgt {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50vh;
  background-color: black;
  overflow: hidden;
}

#maingt h1 {
  margin: 0;
  padding: 0;
  font-family: Founder;
  font-size: 16vw;
  position: absolute;
  top: 61%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

#top-h1gt {
 
  bottom: 50%;
}

#bottom-h1gt {
  top: 0% !important;
}

.contentgt {
  margin-top: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  color: #fff;
  gap: 4vh;
}

.contentgt h4 {
  font-size: 1vw;
  font-family: Founder;
}

.contentgt h3 {
  width: 22%;
  font-size: 3vw;
  font-family: CardinalR;
  text-align: center;
  font-weight: 400;
}

.contentgt .btngt {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7vw;
  height: 2vw;
  border-radius: 50px;
  background-color: #fff;
  color: #0d0d0d;
  font-family: Founder;
}

.contentgt h2 {
  font-size: 20vw;
  font-family: Founder;
}















