/* benifits.css */

#services {
    padding: 50px 0; /* Vertical padding */
    background-color: #f5f5f5; /* Light grey background */
}

.cnt {
    max-width: 1200px; /* Maximum width of the content */
    margin: auto; /* Center align content */
    padding: 0 20px; /* Horizontal padding */
}

.cnt .row h2{
    text-align: center; 
    font-size: 40px; 
    color: black; 
    margin-bottom: 40px; 
}

.reasons__wrapper {
    display: flex; /* Display items in a row */
    flex-wrap: wrap; /* Allow items to wrap */
    gap: 20px; /* Gap between items */
    justify-content: space-between; /* Space out items evenly */
}

.reasons__item {
    background-color: white; /* Background color */
    border-radius: 10px; /* Rounded corners */
    padding: 20px; /* Padding for content */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    flex: 1; /* Allow items to grow */
    min-width: 300px; /* Minimum width */
    box-sizing: border-box; /* Include padding in width calculation */
    position: relative; /* Position relative for pseudo-element */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effects */
}

.reasons__item:hover {
    transform: translateY(-5px); /* Slight lift on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}

.reasons__item_figure {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 6px; /* Figure width */
    height: 100%;
    background-color: #006b95; /* Figure color */
    border-radius: 10px 0 0 10px; /* Rounded left corners */
}

.reasons__item_content {
    padding-left: 20px; /* Padding to offset figure */
}

.reasons__item_title {
    font-size: 24px; /* Title font size */
    color: #333; /* Title text color */
    margin-bottom: 10px; /* Bottom margin */
    font-weight: 500;
}

.reasons__item_text {
    font-size: 16px; /* Text font size */
    color: #666; /* Text color */
    line-height: 1.5; /* Line height for readability */
    margin: 0; /* Reset margin */
}

.reasons__item_text p {
    margin: 0 0 10px; /* Spacing between paragraphs */
}

/* Responsive Design */
@media (max-width: 768px) {

     .cnt .row h2{
        text-align: center; 
        font-size: 29px !important; 
        color: #333; 
        margin-bottom: 40px; 
    }
    .reasons__wrapper {
        flex-direction: column; /* Stack items vertically */
        gap: 30px; /* Increase gap for vertical stacking */
    }
    
    .reasons__item {
        max-width: 100%; /* Full width on smaller screens */
    }
}
