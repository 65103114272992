/* Partner.css */

/* Container for the logo grid */
.logo-grid-container {
    margin-top: 3%;
    margin-bottom: 3%;
    text-align: center;
    padding: 20px;
    background-color: white;
    border-radius: 15px;
    /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); */
  }
  
  /* Heading */
  .heading {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
    padding: 0 10px; /* Added padding for better spacing */
  }
  
  /* Grid layout */
  .logo-grid {
    display: flex;
    justify-content: center; /* Center logos horizontally */
    align-items: center; /* Center logos vertically */
    flex-wrap: wrap;
    gap: 20px; /* Space between logos */
  }
  
  /* Each logo item */
  .logo-item {
    flex: 1 1 30%;
    max-width: 150px; /* Adjust max-width to control logo size */
    text-align: center;
  }
  
  /* Logo image */
  .logo-img {
    max-width: 70%;
    height: auto;
    object-fit: contain;
    transition: transform 0.3s ease; /* Smooth zoom effect on hover */
    padding-top: 30px;
  }
  
  /* Hover effect for logos */
  .logo-item:hover .logo-img {
    transform: scale(1.1); /* Zoom in on hover */
  }
  