/* General FAQ Styles */
.faq-divider {
    height: 2px;
    background-color: #e0e0e0;
    margin: 20px 0;
    margin-bottom: 0 !important;
   
}

.faq-container {
    width: 90%;
    max-width: 1000px; /* Adjust max-width as needed */
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.faq-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.faq-title {
    font-size: 2.5em;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
    position: relative;
}

.faq-title::after {
    content: '';
    display: block;
    width: 50px;
    height: 4px;
    background: #006b95;
    margin: 10px auto 0;
    border-radius: 2px;
}

.faq-collapse {
    width: 100%;
}

.faq-accordion {
    width: 100%;
}

/* FAQ Card Styles */
.faq-card {
    background: #f9f9f9;
    border: none;
    border-radius: 12px;
    margin-bottom: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
}

.faq-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.faq-card-title {
    padding: 20px;
    font-size: 1.3em;
    font-weight: 500;
    color: #333;
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s, color 0.3s;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.faq-card-title::after {
    content: '\002B'; /* Unicode for plus sign */
    font-size: 1.2em;
    transition: transform 0.3s;
}

.faq-active::after {
    transform: rotate(45deg); /* Rotates plus to make an X */
}

.faq-card-title:hover {
    background-color: #006b95;
    color: #fff;
}

.faq-card-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out, padding 0.3s;
    padding: 0 20px;
    background-color: #fff;
    border-top: 1px solid #e0e0e0;
    border-radius: 0 0 12px 12px;
}

.faq-show {
    max-height: 500px; /* Adjust this value based on your content length */
    padding: 20px;
}

.faq-card-text {
    font-size: 1em;
    color: #555;
    line-height: 1.6;
    margin: 0;
    font-weight: 400;
}

/* Animation for content fade-in */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}



@media (max-width: 480px) {
    .faq-card-title {
        
        font-size: 1.0em;
        
    }
}