/* Trusted Partner Container */
.trusted-partner-container {
  padding: 20px;
  width: 100%; /* Ensure full-width container */
  box-sizing: border-box; /* Include padding in width calculation */
  margin: 0 auto; /* Center align container */
  height: 45vh;
}

/* Centered Heading */
.centered-heading {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5em; /* Responsive font size */
  color: #333; /* Color for contrast */
}

/* Partner Slide */
.partner-slide {
  text-align: center;
  padding: 10px;
  flex: 1 1 25%; /* Flexbox for responsive grid */
  box-sizing: border-box; /* Include padding in width calculation */
}

/* Partner Image */
.partner-image {
  width: 150px; /* Fixed width for smaller screens */
  height: auto;
  margin: 0 auto; /* Center align image */
  transition: transform 0.3s ease; /* Smooth hover effect */
  margin-top: 10px;
}

.partner-image:hover {
  transform: scale(1.05); /* Slight zoom on hover */
}

/* Partner Heading */
.partner-heading {
  margin-top: 10px;
  font-size: 1.125em; /* Responsive font size */
  color: #555; /* Subtle color */
}

/* Flexbox Container for Partner Slides */
.partner-flex-container {
  display: flex;
  flex-wrap: wrap; /* Wrap items on smaller screens */
  justify-content: space-between; /* Space items evenly */
  gap: 10px; /* Gap between items */
}

/* Media Queries */

/* Tablets */
@media (max-width: 768px) {
  .trusted-partner-container {
      padding: 15px; /* Adjust padding */
  }

  .centered-heading {
      font-size: 1.3em; /* Adjust font size */
      margin-bottom: 15px; /* Adjust margin */
  }

  .partner-slide {
      flex: 1 1 45%; /* Two items per row */
      padding: 8px; /* Adjust padding */
  }

  .partner-image {
      width: 90px; /* Adjust image size */
  }

  .partner-heading {
      font-size: 1em; /* Adjust font size */
  }
}

/* Mobile Phones */
@media (max-width: 480px) {
  .trusted-partner-container {
      padding: 10px; /* Adjust padding */
  }

  .centered-heading {
      font-size: 1.2em; /* Adjust font size */
      margin-bottom: 10px; /* Adjust margin */
  }

  .partner-slide {
      flex: 1 1 100%; /* One item per row */
      padding: 5px; /* Adjust padding */
  }

  .partner-image {
      width: 80px; /* Adjust image size */
  }

  .partner-heading {
      font-size: 0.9em; /* Adjust font size */
  }
}
