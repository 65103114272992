.about-us-bannercl {
  position: relative;
  width: 100%;
 
}

.banner-containercl {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  height: 100vh;
}

.banner-containercl picture,
.banner-containercl img {
  width: 100%;
  height: 100%;
  
}

.banner-containercl::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
  z-index: 1; /* Ensure the overlay is above the image but below the text */
}

.banner-content-overlaycl {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  width: 80%;
  z-index: 2; /* Ensure the text is above the overlay */
}

.banner-content-overlaycl h1 {
  color: white;
  font-size: 40px;
  text-align: center;
}

.banner-content-overlaycl p {
  color: white;
  font-size: 20px;
}

.client-namecl {
  position: absolute;
  bottom: 10%; /* Adjust as needed */
  right: 10%; /* Adjust as needed */
  text-align: justify;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 1em;
  z-index: 2; /* Ensure the text is above the overlay */
}

.client-namecl p {
  /* color: #003e95; */
  /* font-weight: 500; */
}

/* Responsive Design */
@media (max-width: 1200px) {
  .banner-content-overlaycl h1 {
    font-size: 35px;
  }
  .banner-content-overlaycl p {
    font-size: 18px;
  }
}

@media (max-width: 992px) {
  .banner-content-overlaycl h1 {
    font-size: 30px;
  }
  .banner-content-overlaycl p {
    font-size: 16px;
  }
  .client-namecl {
    font-size: 0.9em;
  }
}

@media (max-width: 768px) {
  .banner-content-overlaycl h1 {
    font-size: 25px;
  }
  .banner-content-overlaycl p {
    font-size: 14px;
  }
  .client-namecl {
    font-size: 0.8em;
    bottom: 8%; /* Adjust as needed */
    right: 8%; /* Adjust as needed */
  }
}

@media (max-width: 576px) {
  .banner-content-overlaycl h1 {
    font-size: 22px;
  }
  .banner-content-overlaycl p {
    font-size: 16px;
  }
  .client-namecl {
    font-size: 1.0em;
    bottom: 5%; /* Adjust as needed */
    right: 5%; /* Adjust as needed */
  }
  .banner-containercl img {
    width: 100%;
    height: 100%;
    object-fit:cover;
    
  }
}
