@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600;800&display=swap');

.preloader {
  height: 100vh;
  width: 100%;
  background: linear-gradient(135deg, #000000, #1a1a1a);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999999999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  overflow: hidden;
  font-family: 'Poppins', sans-serif;
  transition: opacity 0.8s ease;
}

.texts-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  animation: fadeIn 1.2s ease forwards 0.5s;
 
}

.saasverse {
  font-size: 60px;
  font-weight: 800;
  color: white;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-bottom: 0px;
  opacity: 0;
  animation: textBounce 3.9s ease forwards;
 font-family:  '  sans-serif' !important;
}

.tagline {
  font-size: 25px;
  font-weight: 600;
  color: white;
  opacity: 0;
  animation: textBounce 3.8s ease forwards;
  padding-left: 200px;
  
}

.highlight {
  font-family:  '  sans-serif' !important;
  color: #00a1e0;
  
}

/* Animations */
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes textBounce {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  60% {
    opacity: 1;
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(60px);
  }
}


@media (max-width: 480px) {
  .saasverse {
    font-size: 40px;
    letter-spacing: 1px;
  }
  .tagline {
    font-size: 26px;
    padding-left: 0;
    text-align: right;
  }
  .preloader {
    padding: 20px;
  }
}