/* Banner.css */

.bannerL {
    background: linear-gradient(to bottom, #000000, #333333); /* Gradient from black to dark grey */
    border-radius: 20px;
    width: 95%;
    max-width: 1200px; /* Maximum width of the banner */
    margin: auto; /* Center align */
    overflow: hidden; /* Ensure content doesn't overflow */
    box-sizing: border-box; /* Include padding in width calculation */
    margin-bottom: 40px; /* Bottom margin for spacing */
    padding: 40px 0; /* Vertical padding */
    transition: padding 0.3s;
   
}

.bannerL__container {
    padding: 0 20px; /* Horizontal padding */
}

.bannerL__wrapper {
    text-align: center; /* Center align content */
}

.bannerL__title {
    font-size: 40px; /* Responsive title font size */
    color: white; /* Title text color */
    margin-bottom: 20px; /* Bottom margin */
    font-weight: bold;
}

.bannerL__subtitle {
    font-size: 1.25em; /* Responsive subtitle font size */
    color: white; /* Subtitle text color */
    margin-bottom: 30px; /* Bottom margin */
    font-weight: 300;
}

.bannerL__button {
    display: inline-block; /* Display as inline block */
    padding: 12px 24px; /* Padding for button */
    font-size: 1em; /* Button font size */
    color: #fff; /* Button text color */
    background-color: #006b95; /* Button background color */
    border: none; /* Remove border */
    border-radius: 4px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor */
    transition: background-color 0.3s ease; /* Smooth background color transition */
    text-decoration: none; /* Remove underline */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a shadow */
}

.bannerL__button:hover {
    background-color: #005580; /* Darker background color on hover */
}

.bannerL__list {
    display: flex; /* Display items in a row */
    justify-content: space-around; /* Center align items with space around */
    margin-top: 40px; /* Top margin */
    gap: 30px; /* Gap between items */
    flex-wrap: wrap; /* Allow items to wrap on smaller screens */
    padding: 0 10px; /* Padding to fit items within the container */
}

.bannerL__list-item {
    position: relative; /* Position relative for pseudo-element */
    text-align: center; /* Center text */
    flex: 1 1 calc(33.333% - 30px); /* Adjust width to be one-third of container minus gap */
    max-width: calc(33.333% - 30px); /* Ensure it doesn't exceed one-third */
    box-sizing: border-box; /* Include padding in width calculation */
    padding: 20px; /* Padding inside the item */
    border-radius: 10px; /* Rounded corners */
    background-color: rgba(255, 255, 255, 0.1); /* Semi-transparent background */
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-bottom: 20px; /* Margin for spacing */
}

.bannerL__list-item:hover {
    background-color: rgba(255, 255, 255, 0.2); /* Slightly darker background on hover */
    transform: translateY(-5px); /* Lift effect on hover */
}

.bannerL__list-item_num {
    font-size: 2em; /* Responsive number font size */
    color: #006b95; /* Number color */
    margin-bottom: 10px; /* Bottom margin */
    font-weight: bold; /* Make it bold */
}

.bannerL__list-item_text {
    font-size: 1em; /* Responsive text font size */
    color: white; /* Text color */
}

/* Vertical line style */
.bannerL__list-item::after {
    content: '';
    position: absolute;
    top: 50%; /* Position at center vertically */
    right: -15px; /* Adjust distance from the right side */
    transform: translateY(-50%);
    width: 2px; /* Line thickness */
    height: 30px; /* Line height */
    background-color: #666; /* Line color */
}

.bannerL__list-item:last-child::after {
    display: none; /* Remove line from the last item */
}

/* Media Queries */

/* Large Tablets and Small Laptops */
@media (max-width: 1024px) {
    .bannerL__title {
        font-size: 1.75em; /* Adjust title size */
    }

    .bannerL__subtitle {
        font-size: 1.1em; /* Adjust subtitle size */
    }

    .bannerL__list-item {
        flex: 1 1 calc(50% - 30px); /* Adjust width for two items per row */
        max-width: calc(50% - 30px); /* Ensure it doesn't exceed half */
    }
}

/* Tablets */
@media (max-width: 768px) {
    .bannerL__title {
        font-size: 1.5em; /* Adjust title size */
    }

    .bannerL__subtitle {
        font-size: 1em; /* Adjust subtitle size */
    }

    .bannerL__button {
        padding: 10px 20px; /* Adjust padding */
        font-size: 0.9em; /* Adjust font size */
    }

    .bannerL__list-item {
        flex: 1 1 calc(50% - 20px); /* Adjust width for two items per row */
        max-width: calc(50% - 20px); /* Ensure it doesn't exceed half */
        padding: 15px; /* Adjust padding */
    }

    .bannerL__list-item::after {
        height: 20px; /* Adjust line height */
        right: -10px; /* Adjust distance */
    }
}

/* Mobile Phones */
@media (max-width: 480px) {
    .bannerL__title {
        font-size: 1.25em; /* Adjust title size */
    }

    .bannerL__subtitle {
        font-size: 0.9em; /* Adjust subtitle size */
    }

    .bannerL__button {
        padding: 8px 16px; /* Adjust padding */
        font-size: 0.8em; /* Adjust font size */
    }

    .bannerL__list-item {
        flex: 1 1 100%; /* Full width for single item per row */
        max-width: calc(100% - 20px); /* Ensure it doesn't exceed full width */
        padding: 10px; /* Adjust padding */
    }

    .bannerL__list-item::after {
        display: none; /* Remove line for mobile */
    }
}
