/* General Container Styling */
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Section Styling */
  .fifty-fifty {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    background-color: #fff;
    position: relative;
  }
  
  .fifty-fifty::before,
  .fifty-fifty::after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* Grid Layout */
  .grid-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .cell {
    box-sizing: border-box;
    padding: 10px;
  }
  
  .cell.large-6 {
    width: 50%;
  }
  
  .cell.small-12 {
    width: 100%;
  }
  
  /* Image Styling */
  .fifty-image img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .bg-img {
    display: block;
    max-width: 100%;
    height: auto;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
    opacity: 0.1;
  }
  
  /* Text Styling */
  .content-wrap {
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .bold-intro {
    font-weight: 700;
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .flex-content p {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
    margin: 0;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 1024px) {
    .fifty-fifty {
      flex-direction: column;
      padding: 20px 10px;
    }
  
    .cell.large-6 {
      width: 100%;
    }
  
    .content-wrap {
      margin-top: 20px;
    }
  
    .fifty-image img {
      border-radius: 8px 8px 0 0;
    }
  
    .content-wrap {
      border-radius: 0 0 8px 8px;
    }
  }
  
  @media (max-width: 768px) {
    .bold-intro {
      font-size: 1.25rem;
    }
  
    .flex-content p {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .bold-intro {
      font-size: 1.1rem;
    }
  
    .flex-content p {
      font-size: 0.85rem;
    }
  
    .content-wrap {
      padding: 15px;
    }
  }
  