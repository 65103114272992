
.testimcc{
    width: 100%;
    /* position: absolute; */
    top: 50%;
    transform: translateY(-50%);
    margin-top: 20%;
    background-color: white;
}
.testimcc .wrapcc{
    position: relative;
    width: 100%;
    max-width: 1020px;
    padding: 40px 20px;
    margin: auto;
}S
.testimcc .arrowcc{
    display: block;
    position: absolute;
    color: #eee;
    cursor: pointer;
    font-size: 2em;
    top: 50%;
    transform: translateY(-50%);
    transition: all .3s ease-in-out;
    padding: 5px;
    z-index: 22222222;
}
.testimcc .arrowcc:before{
    cursor: pointer;
}
.testimcc .arrowcc.leftcc{
    left: 10px;
    position: absolute;
  
    height: 100%;
    
    align-content: center;
}
.testimcc .arrowcc.rightcc{
    right: 10px;
    position: absolute;
  
    height: 100%;
   
    align-content: center;
}
.testimcc .arrowcc:hover{
    color: #003e95;
}
.testimcc .dotscc{
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 60px;
    left: 0;
    display: block;
    z-index: 3333;
    height: 12px;
}
.testimcc .dotscc .dotcc{
    list-style-type: none;
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid #eee;
    margin: 0 10px;
    cursor: pointer;
    transition: all .5s ease-in-out;
    position: relative;
}
.testimcc .dotscc .dotcc.active,
.testimcc .dotscc .dotcc:hover{
background: #003e95;
border-color: #003e95;
}

.testimcc .dotscc .dotcc.active{
    animation: testim-scale .5s ease-in-out forwards;
}
.testimcc .contcc{
    position: relative;
    overflow: hidden;
}
.testimcc .contcc > div{
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 0 70px 0;
    opacity: 0;
}
.testimcc .contcc > div.inactive{
    opacity: 1;
}
.testimcc .contcc > div.active{
    
    position: relative;
    opacity: 1;
    
}
.testimcc .contcc div .imgcc img{
    display: block;
    width: 100px;
    height: 100px;
    margin: auto;
    border-radius: 50%;
}
.testimcc .contcc div h2{
    color: #003e95;
    font-size: 1.4em;
    margin: 15px 0;
}
.testimcc .contcc div p{
    font-size: 1.15em;
    color: black;
    width: 80%;
    margin: auto;
}

.testimcc .contcc div.active .imgcc img{
    animation: testim-show .5s ease-in-out forwards;
}
.testimcc .contcc div.active h2{
    animation: testim-content-in .4s ease-in-out forwards;
}
.testimcc .contcc div.active p {
    animation: testim-content-in .5s ease-in-out forwards;
}
.testimcc .contcc div.inactive .imgcc img{
    animation: testim-hide .5s ease-in-out forwards;
}
.testimcc .contcc div.inactive h2{
    animation: testim-content-out .4s ease-in-out forwards;
}
.testimcc .contcc div.inactive p {
    animation: testim-content-out .5s ease-in-out forwards;
}

@keyframes testim-scale {
    0% {
        box-shadow: 0px 0px 0px 0px #eee;
    }
    35% {
        box-shadow: 0px 0px 10px 5px #eee;
    }
    70% {
        box-shadow: 0px 0px 10px 5px #003e95;
    }
    100% {
        box-shadow: 0px 0px 0px 0px #003e95;
    }
}

@keyframes testim-content-in {
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes testim-content-out {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(-100%);
    }
}
@keyframes testim-show {
    from {
        opacity: 0;
        transform: scale(0);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}
@keyframes testim-hide {
    from {
        opacity: 1;
        transform: scale(1);
    }
    to {
        opacity: 0;
        transform: scale(0);
    }
}
/* coding with nick */

/* Responsive */

@media all and (max-width: 300px) {
    body {
        font-size: 14px;
    }
}
@media all and (max-width: 500px) {
    .testimcc {
        width: 100%;
        /* position: absolute; */
        top: 50%;
        transform: translateY(-50%);
        margin-top: 20%;
        background-color: white;
        margin-bottom: -133px;
    }
    .testim .arrow{
        font-size: 1.5rem;
    }
    .testim .cont div p{
        line-height: 25px;
    }
}

 

