/* Our.css */

/* Container and Row */
.our-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  gap: 20px; /* Space between columns */
  background-color: #f9f9f9; /* Light background for contrast */
}

.our-row {
  width: 100%; /* Full width for row */
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Gap between columns */
}

/* Column Styles */
.our-col {
  flex: 1 1 300px; /* Responsive flex basis */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  min-width: 300px; /* Minimum width for smaller screens */
  max-width: 600px; /* Limit max width for larger screens */
}

.our-col:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
}

/* Section Content Wrapper */
.our-section-content-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 20px; /* Space between image and text */
}

/* Section Styles */
.our-section {
  flex: 1;
  padding: 20px;
}

.our-section-title {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 15px;
}

.our-section-content {
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
}

.our-section-image {
  width: 100%;
  max-width: 250px;
  border-radius: 10px;
}

/* Responsive Design */

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .our-container {
    padding: 20px 10px;
    gap: 10px;
  }

  .our-row {
    gap: 10px;
  }

  .our-col {
    flex: 1 1 100%;
    padding: 15px;
  }

  .our-section-content-wrapper {
    gap: 10px;
  }

  .our-section-title {
    font-size: 1.4rem;
  }

  .our-section-content {
    font-size: 0.9rem;
  }

  .our-section-image {
    max-width: 100%;
    margin-bottom: 10px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .our-container {
    padding: 30px 15px;
    gap: 15px;
  }

  .our-row {
    gap: 15px;
  }

  .our-col {
    flex: 1 1 100%;
    padding: 18px;
  }

  .our-section-content-wrapper {
    gap: 15px;
  }

  .our-section-title {
    font-size: 1.6rem;
  }

  .our-section-content {
    font-size: 1rem;
  }

  .our-section-image {
    max-width: 100%;
    margin-bottom: 10px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .our-container {
    padding: 40px 20px;
    gap: 20px;
  }

  .our-row {
    gap: 20px;
  }

  .our-col {
    flex: 1 1 45%;
    padding: 20px;
  }

  .our-section-content-wrapper {
    gap: 20px;
  }

  .our-section-title {
    font-size: 1.8rem;
  }

  .our-section-content {
    font-size: 1rem;
  }

  .our-section-image {
    max-width: 100%;
    margin-bottom: 10px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .our-container {
    padding: 50px 30px;
    gap: 20px;
  }

  .our-row {
    gap: 20px;
  }

  .our-col {
    flex: 1 1 45%;
    padding: 25px;
  }

  .our-section-content-wrapper {
    gap: 20px;
  }

  .our-section-title {
    font-size: 2rem;
  }

  .our-section-content {
    font-size: 1rem;
  }

  .our-section-image {
    max-width: 100%;
    margin-bottom: 10px;
  }
}
