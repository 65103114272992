



.reveal {
  background: black;
  padding-left: 30%;
  padding-right: 10%;
  text-align: end;
  padding-top: 93px;
}

.reveal-text {
  font-weight: 800;
  font-size: clamp(3rem, 10vw, 15rem);

  line-height:1.0;
  color: black;
  filter: drop-shadow(0 0 0.06rem rgb(2, 2, 2));
}

.spacing-small {
  /* height: 20vh; */
}

.spacing {
  height: 100vh;
}

@media screen and (max-width: 500px) {
  .reveal {
    padding-left: 10px;
  }
}


.app-container {
    width: 100vw;
    height: 70vh;
    color: #000000;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
  }
  
  .wrapper {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .text {
    font-size: 35px;
    font-weight: 500;
    margin-bottom: 10px;
    color: #02203c;
  }
  
  .note {
    font-size: 18px;
    font-weight: 200;
    margin-bottom: 40px;
    color: #7c8e9a;
  }
  
  .marquee {
    display: flex;
    width: 1200px;
    overflow: hidden;
    user-select: none;
    mask-image: linear-gradient(
      to right,
      hsl(0 0% 0% / 0),
      hsl(0 0% 0% / 1) 10%,
      hsl(0 0% 0% / 1) 90%,
      hsl(0 0% 0% / 0)
    );
  }
  
  @keyframes scrollX {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  
  .marquee-group, .marquee-group2 {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    white-space: nowrap;
    width: 100%;
    animation: scrollX 30s linear infinite;
  }
  
  .marquee-group2 {
    animation-direction: reverse;
    animation-delay: -3s;
  }
  
  .image-group {
    display: grid;
    place-items: center;
    width: clamp(10rem, 1rem + 40vmin, 30rem);
    padding: calc(clamp(10rem, 1rem + 30vmin, 30rem) / 10);
  }
  
  .image {
    object-fit: contain;
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    aspect-ratio: 16/9;
    padding: 5px 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #f9f9f9;
  }
  
  @media (max-width: 480px) {
    .app-container {
      width: 100vw;
      height: 56vh;
      color: #000000;
      position: relative;
      display: flex;
      align-items: normal;
      justify-content: center;
      background-color: black;
      padding-top: 50px;
    }
  }