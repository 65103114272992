/* OurServices.css */

.service {
  padding: 20px;
  text-align: center;
  background-image: url('../../../../../public/assets/images/serviceslogo/backbanner.jpg');
  background-size: cover;
  background-position: center;
  color: #333;
  width: 90%;
  margin: auto;
  border-radius: 30px;
}

.service p {
  margin-bottom: 10px;
  font-size: 18px;
}

.service h2 {
  font-size: 32px;
  margin-bottom: 30px;
}

.four-parts-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  /* padding: 0 40px; */
  box-sizing: border-box;
}

.part {
  flex: 0 0 calc(30.33% - 20px);
  max-width: calc(33.33% - 20px);
  margin-bottom: 30px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  overflow: hidden;
  position: relative;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  border-radius: 20px;
  opacity: 0;
  transform: translateY(20px);
}

.part:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.part.show {
  opacity: 1;
  transform: translateY(0);
}

.part-images {
  display: flex;
  justify-content:center;
  align-items: center;
  margin-bottom: 20px;
}

.part-image {
  width: 60px;
  height: 60px;
  object-fit:contain;
  /* border-radius: 50%; */
  margin-right: 10px;
}

.part-heading {
  font-size: 24px;
  margin-bottom: 10px;
}

.part-text {
  font-size: 14px !important;
  color: #666;
  align-content: flex-start;
}

.tick-mark {
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: 20px;
  color: #4caf50;
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.part:hover .tick-mark {
  opacity: 1;
}

@media (max-width: 992px) {
  .part {
    flex: 0 0 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .part {
    flex: 0 0 calc(100% - 20px);
    max-width: calc(100% - 20px);
  }
}









.scroll-section-outerks {
  display: block;
}

/* .service {
  display: none;
} */

@media screen and (max-width: 768px) {
  .scroll-section-outerks {
    display: none;
  }

  /* .service {
    display: block;
  } */
}