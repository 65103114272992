/* Base Timeline Styling */
.timeline {
  margin: 4em auto;
  position: relative;
  max-width: 46em;
}

.timeline:before {
  background-color: black;
  content: '';
  margin-left: -1px;
  position: absolute;
  top: 0;
  left: 2em;
  width: 2px;
  height: 100%;
}

.timeline-event {
  position: relative;
}

.timeline-event:hover .timeline-event-icon {
  transform: rotate(-45deg);
  background-color: #003e95;
}

.timeline-event:hover .timeline-event-thumbnail {
  box-shadow: inset 40em 0 0 0 #003e95;
}

.timeline-event-copy {
  padding: 2em;
  position: relative;
  top: -1.875em;
  left: 1em;
  width: 80%;
}

.timeline-event-copy h3 {
  font-size: 1.75em;
}

.timeline-event-copy h4 {
  font-size: 1.2em;
  margin-bottom: 1.2em;
}

.timeline-event-copy strong {
  font-weight: 700;
}

.timeline-event-copy p:not(.timeline-event-thumbnail) {
  padding-bottom: 1.2em;
}

.timeline-event-icon {
  transition: transform 0.2s ease-in;
  transform: rotate(45deg);
  background-color: black;
  outline: 10px solid white;
  display: block;
  margin: 0.5em 0.5em 0.5em -0.5em;
  position: absolute;
  top: 7px;
  left: 1em;
  width: 1em;
  height: 1em;
}

.timeline-event-thumbnail {
  transition: box-shadow 0.5s ease-in 0.1s;
  color: white;
  font-size: 0.75em;
  background-color: black;
  box-shadow: inset 0 0 0 0em #443531;
  display: inline-block;
  margin-bottom: 0.7em;
  padding: 0.1em 1em 0.1em 1em;
  border-radius: 14px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .timeline {
    margin: 3em auto;
    max-width: 90%;
  }

  .timeline:before {
    left: 1.5em;
  }

  .timeline-event-copy {
    padding: 1.5em;
    left: 1em;
    width: 85%;
  }

  .timeline-event-copy h3 {
    font-size: 1.5em;
  }

  .timeline-event-copy h4 {
    font-size: 1em;
    margin-bottom: 1em;
  }

  .timeline-event-copy p:not(.timeline-event-thumbnail) {
    padding-bottom: 1em;
  }

  .timeline-event-icon {
    left: 1.5em;
    width: 0.8em;
    height: 0.8em;
  }

  .timeline-event-thumbnail {
    font-size: 0.7em;
    padding: 0.1em 0.8em;
  }
}

@media (max-width: 480px) {
  .timeline {
    margin: 2em auto;
    max-width: 100%;
  }

  .timeline:before {
    left: 1em;
  }

  .timeline-event-copy {
    padding: 1em;
    left: 1em;
    width: 90%;
  }

  .timeline-event-copy h3 {
    font-size: 1.2em;
  }

  .timeline-event-copy h4 {
    font-size: 0.9em;
    margin-bottom: 0.8em;
  }

  .timeline-event-copy p:not(.timeline-event-thumbnail) {
    padding-bottom: 0.8em;
  }

  .timeline-event-icon {
    left: 1em;
    width: 0.6em;
    height: 0.6em;
  }

  .timeline-event-thumbnail {
    font-size: 0.6em;
    padding: 0.1em 0.5em;
  }
}
