

.hero__headerks {
  height: 100vh;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.hero__headerks h1 {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: var(--headlineBig);
  color: var(--primaryTextColor);
}

.hero__headerks h2 {
  letter-spacing: var(--headlineSmall);
  font-size: var(--headlineSmall);
  text-transform: uppercase;
  color: var(--primaryColor);
}

.scroll-section-outerks {
  background-color: white;
  overflow: hidden;
  
}

.scroll-section-innerks {
  
  height: 100vh;
  width: 400vw;
  display: flex;
  flex-direction: row;
  position: relative;
}




.scroll-sectionks {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.scroll-sectionks h3 {
  color: var(--primaryTextColor);
  letter-spacing: var(--headlineMedium);
  font-size: var(--headlineMedium);
  text-transform: uppercase;
}

.footerks {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerks span {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: var(--headlineBig);
  color: var(--primaryTextColor);
}



.scroll-sectionks .logoks {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoks svg {
  display: block;
  height: auto;
  max-width: 100%;
}

 .content-containerks {
  display: flex;
  gap: 3rem;
  flex-direction: column;
  justify-content: center;
}

 .contentks {
  font-size: 1.2rem;
  color: #737373;
  line-height: 1.2;
}


.scroll-section-outerks .heading-secondaryks {
  width: calc(128rem + 160px);
  margin-top: 60px;
  padding: 0 80px;
  margin-left: auto;
  margin-right: auto;
  font-size: 2.5rem;
}

.list-contentks {
  margin: 0 auto;
  margin-top: 2rem;
  -moz-column-count: 2;
  column-count: 2;
  max-width: 650px;
}

.list-contentks li {
  list-style: disc;
  list-style-position: outside;
  font-size: 1.1rem;
  line-height: 1.6;
  font-weight: 500;
  color:#2a2828;
}

.list-contentks li a{
  font-size: 1.1rem;
  color: #003e95;
  text-decoration: underline;
}

.logoks img{
  width: 70%;
}


.info-box-wrappertag{
  position:relative;
  top: -300px;
  left: 600px;
  /* background-color: aquamarine; */
  padding: 10px;
  width:600px;
}

.info-box-wrappertag h2{
  color: #003e95;
  line-height: 1.2;
  margin-bottom: 20px;
}
.info-box-wrappertag a{
  color: #003e95;
 text-decoration: none;
 font-weight: 500;
}
.info-box-wrappertag p{
margin-bottom: 20px;
 
}

.maintag{
  position: absolute;
  top: 80px;
  left: 80px;
}

.carouseltag{
  height: 100vh;
  background-color: white;
}


.content-containerks li {
  text-decoration: none;
  color: #737373;
  
}
.content-containerks li:hover{
  text-decoration: none;
  color: #003e95;
  
}

.content-containerks li a{
  text-decoration: none;
  color: #737373;
  
}
.content-containerks li a:hover{
  color: #003e95;
  
}

























@media (max-width: 768px) {

  .contentks {
    font-size: 1rem;
    line-height: 1.4;
  }

  .scroll-section-innerks {
    width: 300vw; 
  }
  
  .list-contentks {
    -moz-column-count: 1;
    column-count: 1;
  }
}







.scroll-section-innerks {
  display: flex;
  flex-direction: row; 
  width: max-content; 
}

.scroll-sectionks {
  flex: 0 0 100vw; 
  width: 100vw;
  height: 100vh; 
  box-sizing: border-box;
}



