/* Disclaimer.css */
.disclaimer {
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-family: Arial, sans-serif;
  }
  
  .disclaimer h2 {
    margin-top: 0;
    color: #333;
  }
  
  .disclaimer h3 {
    margin-top: 20px;
    color: #555;
  }
  
  .disclaimer p {
    margin: 10px 0;
    line-height: 1.6;
    color: #666;
  }
  
  .disclaimer a {
    color: #007bff;
    text-decoration: none;
  }
  
  .disclaimer a:hover {
    text-decoration: underline;
  }
  