.carousel {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background-color: black;
}

.carousel .list {
  position: relative;
  width: 100%;
  height: 100%;
}

.carousel .list .item {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  padding: 0;
  width: 180px;
  height: 250px;
  position: absolute;
  top: 80%;
  transform: translateY(-70%);
  left: 70%;
  border-radius: 20px;
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.3);
  background-position: 50% 50%;
  background-size: cover;
  transition: 1s;
}

.carousel .list .item.active {
  top: 0;
  left: 0;
  transform: translate(0, 0);
  border-radius: 0;
  width: 100%;
  height: 100%;
}

.list .item .content {
  position: absolute;
  top: 50%;
  left: 100px;
  transform: translateY(-50%);
  width: 400px;
  text-align: left;
  color: #fff;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.carousel .list .item.active .content {
  opacity: 1;
}

.content .title {
  font-size: 60px;
  text-transform: uppercase;
  color: #003e95;
  font-weight: bold;
  line-height: 1;
  text-align: justify;
}

.content .name {
  font-size: 50px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1;
  text-shadow: 3px 4px 4px rgba(255, 255, 255, 0.8);
}

.content .des {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 18px;
  margin-left: 5px;
}

.content .btn button {
  background: #003e95;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  color: #fff;
  border-radius: 5px;
  transition: background 0.3s ease;
}

.content .btn button:hover {
  background: black;
}

.arrows {
  position: absolute;
  top: 80%;
  right: 52%;
  z-index: 100;
  width: 300px;
  max-width: 30%;
  display: flex;
  gap: 10px;
  align-items: center;
}

.arrows button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #003e95;
  color: #fff;
  border: none;
  outline: none;
  font-size: 16px;
  font-family: monospace;
  font-weight: bold;
  transition: 0.5s;
  cursor: pointer;
}

.arrows button:hover {
  background: #fff;
  color: #000;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .content .title {
    font-size: 40px;
  }
  .content .name {
    font-size: 30px;
  }
  .list .item {
    width: 150px;
    height: 200px;
  }
}

@media (max-width: 768px) {
  .content .title {
    font-size: 30px;
  }
  .content .name {
    font-size: 25px;
  }
  .list .item {
    width: 120px;
    height: 180px;
  }
  .list .item .content {
    width: 300px;
    left: 50px;
  }
}

@media (max-width: 480px) {
  .content .title {
    font-size: 25px;
  }
  .content .name {
    font-size: 20px;
  }
  .list .item {
    width: 100px;
    height: 150px;
  }
  .list .item .content {
    /* width: 250px; */
    left: 30px;
  }
  .arrows {
    width: 200px;
    gap: 5px;
    top: 85%;
  }
  .arrows button {
    width: 40px;
    height: 40px;
  }
}
