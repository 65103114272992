/* Mobile Navbar Styles */
.mobile-nav {
  display: none; /* Hide by default */
  flex-direction: column;
  background: white;
  width: 100%;
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 999;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  border-top: 1px solid #ddd; /* Add a border on top */
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transform: translateY(-100%);
  opacity: 0;
}

.mobile-nav--open {
  display: flex; /* Display when open */
  transform: translateY(0);
  opacity: 1;
}

.mobile-nav__wrapper {
  padding: 15px;
}

.mobile-nav__dropdown {
  margin-bottom: 15px;
}

.mobile-nav__dropdown-title {
  font-size: 18px;
  font-weight: bold;
  color: black;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd; /* Add border to dropdown title */
  border-radius: 4px; /* Rounded corners */
  background: #f9f9f9; /* Background color */
  transition: background 0.3s;
}

.mobile-nav__dropdown-title:hover {
  background: #f1f1f1; /* Change background on hover */
}

.mobile-nav__dropdown-icon {
  font-size: 14px;
  transform: rotate(0);
  transition: transform 0.2s ease-in-out;
}

.mobile-nav__dropdown-icon.open {
  transform: rotate(180deg);
}

.mobile-nav__dropdown-content {
  display: none; /* Hide by default */
  flex-direction: column;
  padding-left: 20px;
  transition: max-height 0.3s ease-in-out;
  max-height: 0;
  overflow: hidden; /* Smooth transition */
}

.mobile-nav__dropdown-content.active {
  display: flex;
  max-height: 1000px; /* Arbitrary high value for animation */
}

.mobile-nav__content-item {
  padding: 10px 0;
  color: black;
}

.mobile-nav__link {
  color: black;
  text-decoration: none;
  padding: 10px;
  display: block; /* Block display for better spacing */
  transition: background 0.3s, color 0.3s;
}

.mobile-nav__link:hover {
  color: #007BFF; /* Change color on hover */
  background: #f0f0f0; /* Change background on hover */
  border-radius: 4px; /* Rounded corners on hover */
}

.hamburger {
  color: black;
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: relative;
  z-index: 1000;
}

/* General mobile layout adjustments */
@media (max-width: 768px) {
  .mobile-nav {
      position: fixed;
      height: 100vh; /* Full screen height */
      overflow-y: auto; /* Scroll if content exceeds height */
  }

  .mobile-nav__link {
      padding: 15px 20px;
  }

  .hamburger {
      position: absolute;
      top: 15px;
      right: 15px;
  }
}

/* Additional styles for open/close animations */
.mobile-nav--close {
  transform: translateY(-100%);
  opacity: 0;
}
.hamburger {
  width: 30px; /* Width of the hamburger icon */
  height: 24px; /* Height of the hamburger icon */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  position: absolute;
  top: 25px;
  right: 0px;
  z-index: 1000;
  color: #003e95;
  font-size: 25px;


}

.hamburger span {
  display: block;
  height: 4px; /* Height of the hamburger lines */
  background-color: white; 
  border-radius: 2px; /* Rounded corners */
  transition: transform 0.3s, background-color 0.3s;
  
}

/* Optional: Hamburger open/close animation */
.hamburger.open span:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.open span:nth-child(2) {
  opacity: 0;
}

.hamburger.open span:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}
