/* General Styling */
.training-service {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Intro Wrap */
  .training-service-intro-wrap {
    margin-bottom: 30px;
    text-align: center;
  }
  
  /* Title Border */
  .training-service-title-bordered {
    /* border-bottom: 2px solid #007ab8;  */
    display: inline-block;
    padding-bottom: 10px;
  }
  
  /* Title with Lines */
  .training-service-title-with-lines {
    margin: 0;
    font-size: 2rem;
    font-weight: 700;
    color: #333; /* Customize the color */
  }
  
  /* Flip Cards Container */
  .training-service-flip-cards-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  /* Flip Cards Wrap */
  .training-service-flip-cards-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  /* Flip Card Outer */
  .training-service-flip-card-outer {
    perspective: 1000px;
    width: 350px; /* Adjust width as needed */
    margin: 10px;
    margin: 0 30px;
  }
  
  /* Flip Card */
  .training-service-flip-card {
    width: 100%;
    height: 400px; /* Set a consistent height */
    position: relative;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  
  .training-service-flip-card:hover {
    transform: rotateY(180deg);
  }
  
  .training-service-flip-card-body {
    width: 100%;
    height: 100%;
    position: absolute;
    transform-style: preserve-3d;
    backface-visibility: hidden;
  }
  
  /* Card Front */
  .training-service-flip-card-front,
  .training-service-flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 8px;

  }

  
  
  .training-service-flip-card-front {
    background-color: #f8f9fa; /* Light background color */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .training-service-flip-card-front img {
    width: 80px; /* Adjust image size as needed */
    height: 80px;
    margin-bottom: 20px;
  }
  
  .training-service-flip-card-front h2,
  .training-service-flip-card-front h3 {
    margin: 10px 0;
    font-size: 1.2rem;
    color: #333;
  }
  
  /* Card Back */
  .training-service-flip-card-back {
    background-color: #007ab8; /* Dark background color */
    color: white;
    transform: rotateY(180deg);
    text-align: center;
  }
  
  .training-service-back-inner p {
    font-size: 1rem;
    line-height: 1.6;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 1024px) {
    .training-service-title-with-lines {
      font-size: 1.8rem;
    }
  
    .training-service-flip-cards-container {
      flex-direction: column;
      align-items: center;
    }
  
    .training-service-flip-card-outer {
      width: 90%;
      max-width: 400px;
    }
  
    .training-service-flip-card {
      height: 350px; /* Adjust height for medium screens */
    }
  }
  
  @media (max-width: 768px) {
    .training-service-title-with-lines {
      font-size: 1.5rem;
     
    }
  
    .training-service-flip-card-front h2,
    .training-service-flip-card-front h3 {
      font-size: 1rem;
    }
  
    .training-service-flip-card-front img {
      width: 60px;
      height: 60px;
    }
  
    .training-service-flip-card-back p {
      font-size: 0.9rem;
    }
  
    .training-service-flip-card {
      height: 300px; /* Adjust height for smaller screens */
    }
    .training-service-flip-card-outer {
      perspective: 1000px;
      width: 350px; /* Adjust width as needed */
      margin: 10px;
      margin: 20px 30px;
    }
  }
  
  @media (max-width: 480px) {
    .training-service-flip-card-back {
      position: absolute;
      width: 100%;
      height: auto;
      backface-visibility: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      border-radius: 8px;
  margin-left: -35px;
    }
    .training-service-flip-card-outer {
      perspective: 1000px;
      width: 350px; /* Adjust width as needed */
      margin: 10px;
      margin: 20px 30px;
    }
    .training-service-title-with-lines {
      font-size: 1.2rem;
    }
  
    .training-service-flip-card-front h2,
    .training-service-flip-card-front h3 {
      font-size: 0.9rem;
    }
  
    .training-service-flip-card-front img {
      width: 50px;
      height: 50px;
    }
  
    .training-service-flip-card-back p {
      font-size: 0.85rem;
    }
  
    .training-service-flip-card {
    
      height: 480px;

      width: 250px;
     
    }
    
  }
  