/* ButtonWithGlow.css */

.glow-button {
    padding: 10px 25px;
    border-radius: 10px;
    border: none;
    background-color: #003e95; /* Blue background color */
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 0 20px rgba(0, 123, 255, 0.5); /* Initial shadow */
    animation: glow 1.5s infinite alternate; /* Apply the glow animation */
    transition: all 0.3s ease; /* Smooth transition */
  }
  
  .glow-button:hover {
    transform: scale(1.05); /* Slightly enlarge on hover */
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    }
    to {
      box-shadow: 0 0 20px rgba(0, 123, 255, 1);
    }
  }
  