/* ContactInfo.css */

/* Container holding both sections */
.contact-info-container {
    display: flex;
    flex-direction: row; /* Ensure row direction */
    width: 90vw;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    gap: 20px; /* Adds spacing between the sections */
  }
  
  /* Both sections take up approximately 50% of the width */
  .contact-details, .connect-with-us {
    flex: 1; /* Each section takes equal space */
    padding: 20px;
    background-color: white;
    border-radius: 18px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column; /* Ensure contents stack vertically */
  }
  
  /* Header styles */
  .contact-details h2{
    color: black;
    margin-bottom: 15px;
    text-align: center;
  }
  .connect-with-us h2{
    color: white;
    margin-bottom: 15px;
    text-align: center;
  }
  
  /* Text styles */
  .contact-details p, .connect-with-us p {
    line-height: 1.6;
    margin-bottom: 10px;
  }
  
  .social-links {
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: center; /* Center items horizontally */
    gap: 20px; /* Space between icons */
  }
  
  .social-links li {
    display: flex;
    align-items: center; /* Center items vertically */
    margin-bottom: 90px;
  }
  
  .social-links a {
    text-decoration: none;
    color: white;
    font-size: 24px; /* Ensure icons are large enough */
  }
  
  
  .social-links a:hover {
    text-decoration: underline;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .contact-info-container {
      flex-direction: column; /* Stack sections vertically on small screens */
    }
    
    .contact-details, .connect-with-us {
      flex: 1; /* Full width for each section */
      padding: 15px;
    }
  }
  