.scroll-banner-animation {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .circle {
    position: absolute;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
  }
  
  .circle1 {
    left: 50%;
    transform: translateX(-50%);
    background-color: #FFC0CB; /* Pink color */
  }
  
  .circle2 {
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    background-color: #F3C523; /* Yellow color */
  }
  
  .circle.combined {
    width: 100%; /* Full width when combined */
    height: 80px; /* Height when combined */
    border-radius: 0; /* No border radius when combined */
    bottom: 0; /* Align to bottom when combined */
    left: 0; /* Align to left when combined */
    transform: none; /* Reset transform when combined */
    transition: all 0.5s ease-in-out; /* Transition for smooth animation */
  }
  
  .banner {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background: linear-gradient(95.2379deg, rgba(253, 127, 172, 0.992) 8.0357%, rgba(243, 197, 35, 0.992) 89.9601%);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .banner.show {
    opacity: 1; /* Show banner when 'show' class is added */
  }
  
  .banner-text {
    color: #fff; /* White text color */
    font-size: 24px;
  }
  