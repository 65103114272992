.slider-containerc {
  width: 95%;
  margin: auto;
  padding: 20px 0;
  position: relative;
  background-color: #efefef;
  height: auto;
  margin-bottom: 3%;
  border-radius: 30px;
  overflow: hidden; /* Hide overflow for a cleaner look */
  
}

.certitext h2 {
  font-weight: 300;
  text-align: center;
  margin-bottom: 2%;
  font-size: 2.5rem;
}

.slick-slidec {
  padding: 0 10px; /* Add horizontal padding for spacing */
  box-sizing: border-box;
  display: flex;
  justify-content: center; /* Center images */
  align-items: center; /* Vertically center images */
}

.slick-slidec img {
  width: auto; /* Adjust width naturally */
  max-width: 100%; /* Ensure the image does not exceed container width */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px;
  object-fit: contain; /* Ensure images are fully visible */
  margin: 0 auto; /* Center horizontally */
}

.arrowc {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  cursor: pointer;
  z-index: 2;
  border-radius: 50%;
  transition: background 0.3s ease;
}

.arrowc:hover {
  background: rgba(0, 0, 0, 0.7);
}

.next-arrowc {
  right: -20px;
}

.prev-arrowc {
  left: -20px;
}

.slick-dotsc {
  bottom: -30px;
}

.slick-dotsc li button:before {
  font-size: 12px;
  color: #fff;
}

/* Adjust the slick-track to ensure the gap is maintained */
.slick-track {
  display: flex;
  gap: 20px; /* Add gap between slides */
  align-items: center; /* Center-align images */
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .slick-slidec {
    padding: 0 8px;
  }
  .next-arrowc {
    right: -15px;
  }
  .prev-arrowc {
    left: -15px;
  }
  .certitext h2 {
    font-size: 2rem;
  }
}

@media (max-width: 992px) {
  .slick-slidec {
    padding: 0 6px;
  }
  .next-arrowc {
    right: -10px;
  }
  .prev-arrowc {
    left: -10px;
  }
  .certitext h2 {
    font-size: 1.75rem;
  }
}

@media (max-width: 768px) {
  .slick-slidec {
    padding: 0 4px;
  }
  .next-arrowc {
    right: -8px;
  }
  .prev-arrowc {
    left: -8px;
  }
  .certitext h2 {
    font-size: 1.5rem;
  }
  .slick-slidec img {
    width: auto; /* Let width adjust naturally */
    max-width: 80%; /* Maximum width to ensure it's smaller */
    height: auto; /* Maintain aspect ratio */
    margin: 0 auto; /* Center horizontally */
  }
}

@media (max-width: 576px) {
  .slick-slidec {
    padding: 0 2px;
  }
  .arrowc {
    display: none; /* Hide arrows on small screens */
  }
  .certitext h2 {
    font-size: 1.2rem;
  }
  .slick-slidec img {
    width: auto; /* Let width adjust naturally */
    max-width: 60%; /* Further reduce width for smaller screens */
    height: auto; /* Maintain aspect ratio */
    margin: 0 auto; /* Center horizontally */
  }
}
