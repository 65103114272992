/* Footer.css */

.footer {
    /* background-image: url('../../../../public/assets/images/footer3.png');  */
    background-size: cover; /* Cover the entire footer */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; 
    color: white; /* Ensure text is visible on the background */
    padding: 30px 0; 
 background: linear-gradient(180deg, #0d6892, #147b7a);

    position: relative; 
    margin-top: 0 !important;
    /* height: 100%; */
    /* padding-top: 30px; */
    
   
}

.footer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: rgba(0, 0, 0, 0.2);  */
    z-index: 1; /* Layer it behind the text */
}

.footer > .container {
    position: relative; 
    z-index: 2;
    width: 90%;
    margin: auto;
    /* margin-top: -50px; */
}

.footer h5 {
    margin-bottom: 15px; /* Space below the heading */
    font-size: 20px;
}

.footer-list {
    padding: 0;
    list-style: none;
}

.footer-list li {
    margin-bottom: 10px; /* Space between list items */
    
}

.footer a {
    color: #fff; /* White links */
    text-decoration: none; /* Remove underline from links */
    font-style: normal;
}

.footer a:hover {
    text-decoration: underline; /* Underline on hover */
}

.footer-row {
    display: flex;
    flex-wrap: wrap; /* Wrap items on smaller screens */
    justify-content: space-between; /* Space out the columns */
}

.footer-column {
    flex: 1; /* Flex-grow to fill the container */
    min-width: 200px; /* Minimum width to prevent collapsing */
    margin: 20px; 
    font-style: normal;
}

.footer-logo {
    width: 170px;
    height: auto;
    display: block;
    margin-bottom: 15px;
}

.footer-address {
    font-style: normal;
    margin-bottom: 15px;
}

.footer-social {
    display: flex;
    gap: 15px; /* Adjust the gap between icons */
    font-style: normal;
}

.footer-social a {
    color: #fff;
    text-decoration: none;
}

.footer-social a:hover {
    text-decoration: underline;
}

.footer-divider {
    width: 100%;
    border: 0;
    height: 1px;
    background: #ddd; /* Line color */
    margin: 20px 0; /* Space around the line */
}

.footer-values-row {
    display: flex;
    flex-wrap: wrap; /* Wrap items on smaller screens */
    justify-content: space-between; /* Distribute values evenly */
    margin-top: 20px; /* Space above the values section */
}

.footer-values-column {
    flex: 1; /* Allow the values to grow and fill the space */
    min-width: 200px; /* Minimum width for each column */
    margin: 10px; /* Space between columns */
}

.footer-values-column h5 {
    margin-bottom: 15px; /* Space below the heading */
}
.footer-values-column h3 {
    padding-top: 30px;
}


.footer-values-list {
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap; /* Wrap items to fit in a row */
    gap: 15px; /* Space between value items */
    justify-content: space-between; /* Distribute items evenly */
}

.footer-values-list li {
    flex: 1 1 200px; /* Allow items to grow and wrap */
    display: flex;
    align-items: center;
    text-align: center;
    padding: 10px;
}

.footer-values-list img {
    width: 40px; /* Adjust size as needed */
    height: 40px;
    margin-right: 10px;
}


@media (max-width: 768px) {
    .footer-values-row {
        flex-direction: column; /* Stack the columns vertically */
        align-items: center; /* Center align the columns */
    }

    .footer-values-list {
        flex-direction: column; /* Stack items vertically */
        align-items: center;
    }

    .footer-values-list li {
        width: 100%; /* Ensure full width on smaller screens */
    }
    .footer-values-list li {
        flex: 1 1 00px; /* Allow items to grow and wrap */
        display: flex;
        flex-direction: column; /* Stack items vertically */
        align-items: center;
        padding: 10px;
    }
    .footer-values-column h3 {
       font-size: 30px;
    }
}


.footer-copyright {
    text-align: center;
    font-size: 0.9em;
    color: #fff;
    /* padding: 10px 0; */
    /* margin-top: 10px; */
    /* height: 40px; */
    
}

/* ResponsiveFooter.css */

.responsive-footer {
    padding: 20px;
   
  }
  
  .responsive-footer-column {
    width: 100%;
    margin: auto;
  }
  
  .responsive-footer-list {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
  }
  
  .responsive-footer-list li {
    flex: 0 0 auto;
    text-align: center;
  }
  
  .responsive-footer-list img {
    width: auto;
    height: 60px;
  }
  
  @media (max-width: 1200px) {
    .responsive-footer-list img {
      height: 55px;
    }
  }
  
  @media (max-width: 992px) {
    .responsive-footer-list img {
      height: 50px;
    }
  }
  
  @media (max-width: 768px) {
    .responsive-footer-list {
      justify-content: center;
      gap: 15px;
    }
    .responsive-footer-list img {
      height: 45px;
    }
  }
  
  @media (max-width: 576px) {
    .responsive-footer-list {
      justify-content: space-between;
    }
    .responsive-footer-list li {
      flex: 0 0 calc(33.33% - 10px);
      max-width: calc(33.33% - 10px);
    }
    .responsive-footer-list img {
      height: 60px;
    }
    .responsive-footer-list .last-logo {
      flex: 0 0 100%;
      max-width: 100%;
      text-align: center;
      margin-top: 10px;
    }
  }
  