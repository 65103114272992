/* Batches Container */
.batches-container {
  width: 90%;
  margin: 20px auto;
  max-width: 900px;

  margin-top: 60px;
}

/* Tabs Section */
.tabs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: 2px solid #003e95;
}

.batches-heading {
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  flex: 1;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  border: 2px solid transparent;
  border-radius: 10px;
}

.batches-heading.active {
  background-color: #003e95;
  color: white;
  border-color: #003e95;
}

.batches-heading:not(.active):hover {
  background-color: #e7f0ff;
  color: #003e95;
}

/* Batch Items */
.batches-content {
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.batch-item {
  margin-bottom: 15px;
  padding: 15px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.batch-item:hover {
  transform: translateY(-3px);
}

.batch-item h3 {
  margin: 0 0 10px 0;
  color: #003e95;
  font-size: 20px;
}

.batch-item p {
  margin: 5px 0;
  color: #555;
  font-size: 16px;
}

/* Responsive Design */
@media (max-width: 600px) {
  .batches-heading {
    font-size: 16px;
    /* padding: 8px 15px; */
   
  }

  .batch-item h3 {
    font-size: 18px;
  }

  .batch-item p {
    font-size: 14px;
  }
}
