@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.carousel {
  width: 100vw;
  height: 100vh;
  margin-top: -50px;
  overflow: hidden;
  position: relative;
  /* background-color: black; */
}

.carousel .list {
  position: relative;
  width: 100%;
  height: 100%;
}

.carousel .list .item {
  width: 180px;
  height: 250px;
  position: absolute;
  top: 80%;
  transform: translateY(-70%);
  left: 70%;
  border-radius: 20px;
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.3);
  background-position: 50% 50%;
  background-size: cover;
  /* z-index: 100; */
  transition: 1s;
}


.carousel .list .item.active {
  top: 0;
  left: 0;
  transform: translate(0, 0);
  border-radius: 0;
  width: 100%;
  height: 100%;
}

.list .item .content {
  position: absolute;
  top: 50%;
  left: 100px;
  transform: translateY(-50%);
  width: 400px;
  text-align: left;
  color: #fff;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.carousel .list .item.active .content {
  opacity: 1;
}

.content .title {
  font-size: 60px;
  text-transform: uppercase;
  color: #14ff72cb;
  font-weight: bold;
  line-height: 1;
}

.content .name {
  font-size: 50px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1;
  text-shadow: 3px 4px 4px rgba(255, 255, 255, 0.8);
}

.content .des {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 18px;
  margin-left: 5px;
}

.content .btn button {
  background: #14ff72cb;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  color: #fff;
  border-radius: 5px;
  transition: background 0.3s ease;
}

.content .btn button:hover {
  background: #12e066;
}

.arrows {
  position: absolute;
  top: 50%;
  left: 10px;
  right: 10px;
  display: flex;
  justify-content:center;
  z-index: 1000;
}

.arrows button {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 24px;
  border-radius: 50%;
}

.timeRunning {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 0px;
  background-color: #fff;
  animation: runningTime 7s linear 1 forwards;
 
}

@keyframes runningTime {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}














.arrows{
    position: absolute;
    top: 80%;
    right: 52%;
    z-index: 100;
    width: 300px;
    max-width: 30%;
    display: flex;
    gap: 10px;
    align-items: center;
}

.arrows button{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #14ff72cb;
    color: #fff;
    border: none;
    outline: none;
    font-size: 16px;
    font-family: monospace;
    font-weight: bold;
    transition: .5s;
    cursor: pointer;
}

.arrows button:hover{
    background: #fff;
    color: #000;
}


/* .carousel .list .item:nth-child(1){
    top: 0;
    left: 0;
    transform: translate(0, 0);
    border-radius: 0;
    width: 100%;
    height: 100%;
}


.carousel .list .item:nth-child(2){
    left: 10%;
}
.carousel .list .item:nth-child(3){
    left: 15%;
} */









