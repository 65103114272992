/* index.css or App.css */

body {
    font-family: 'Poppins', sans-serif; /* Apply Poppins as the default font for the entire body */
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Poppins', sans-serif; /* Apply Poppins for headings */
  }
  
 
  h2 {
    font-size: 40px ; 
    font-weight: 500 !important;
    /* color: black; */
    
   
}
 h1 {
  /* font-size: 40px !important; */

  font-weight: 500 !important;
}