/* Slider container */
.slider-container {
  width: 100%;
  overflow: hidden;
}

/* Slider */
.slider {
  position: relative;
  width: 100%;
  height: 100vh; /* Adjust height as needed */
  overflow: hidden;
}

/* Slide */
.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.slide.active {
  opacity: 1;
}

.slide::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Adjust opacity as needed */
}

/* Slide content */
.slide-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  width: 80%; /* Adjust width for responsiveness */
  max-width: 800px; /* Limit maximum width */
}

.slide-content h1 {
  font-size: 4rem;
  font-weight: 500;
  margin: 0;
  color: white;
  line-height: 1.2; /* Adjust line height for readability */
}

/* Text Row */
.text-row {
  display: inline-flex; /* Ensure content is in one row */
  align-items: center; /* Vertically align items */
}

.text-row span {
  font-size: 60px; /* Adjust to match the text size */
  font-weight: bold;
}

/* Slider buttons */
.slide-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  color: #fff;
  border: 1px solid white;
  cursor: pointer;
  padding: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  z-index: 10;
  transition: background-color 0.3s ease;
}

.slide-btn:hover {
  background-color: #006b95;
  border-color: white;
}

.slide-btn.prev {
  left: 10px;
}

.slide-btn.next {
  right: 10px;
}

/* Slider info */
.slider-info {
  position: relative;
  width: 90%;
  height: 16vh;
  margin: 0 auto;
  background: linear-gradient(45deg, #000000, #575757);
  padding: 20px;
  margin-top: -50px;
  z-index: 2;
  border-radius: 40px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.word {
  padding: 10px;
  color: #006b95;
  font-weight: bold;
  font-size: 1.5rem;
  border-radius: 5px;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .slide-content h1 {
    font-size: 3rem;
  }
  .slide-btn {
    width: 35px;
    height: 35px;
    font-size: 1.3rem;
  }
}

@media (max-width: 992px) {
  .slide-content h1 {
    font-size: 2.5rem;
  }
  .slide-btn {
    width: 30px;
    height: 30px;
    font-size: 1.2rem;
  }
}

@media (max-width: 768px) {
  .slide-content h1 {
    font-size: 2rem;
  }
  .slide-btn {
    width: 25px;
    height: 25px;
    font-size: 1rem;
  }
  .slider-info {
    display: none;
  }
}

@media (max-width: 576px) {
  .slide-content h1 {
    font-size: 1.8rem;
  }
  .slide-btn {
    display: none;
  }
}

.animated-text-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Full viewport height */
}

svg {
  font-family: 'Russo One', sans-serif;
  width: 100%;
  height: 100%;
}

svg text {
  text-transform: uppercase;
  animation: stroke 5s infinite alternate;
  stroke-width: 2;
  stroke: #365fa0;
  font-size: 140px;
}

@keyframes stroke {
  0% {
    fill: rgba(72,138,20,0);
    stroke: rgba(54,95,160,1);
    stroke-dashoffset: 25%;
    stroke-dasharray: 0 50%;
    stroke-width: 2;
  }
  70% {
    fill: rgba(72,138,20,0);
    stroke: rgba(54,95,160,1);
  }
  80% {
    fill: rgba(72,138,20,0);
    stroke: rgba(54,95,160,1);
    stroke-width: 3;
  }
  100% {
    fill: rgba(72,138,204,1);
    stroke: rgba(54,95,160,0);
    stroke-dashoffset: -25%;
    stroke-dasharray: 50% 0;
    stroke-width: 0;
  }
}

.text-row .company-text {
  margin-left: 50px; /* Add space between text and "Company" */
  font-weight: 500;
}

.highlight {
  color: #00a1e0;
  font-weight: bold;
}

/* Background images for slides */
.slide:nth-of-type(1) {
  background-image: url('../../../../../public/assets/images/banner/banner01.jpg');
}

.slide:nth-of-type(2) {
  background-image: url('../../../../../public/assets/images/websiteimages/aboutbanner.png');
  /* background-image: url('../..//assets/images/websiteimages/aboutbanner.png'); */
}
